import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, CardHeader, Container, Col, Row, Table, CardTitle, CardText, Button, } from "reactstrap";
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { history } from '_helpers/history';
import { subscriptionAction } from '_actions/subscription.action';
import { connect, useSelector, useDispatch } from 'react-redux';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import SimpleHeader from "components/Headers/SimpleHeader";
import { eventService } from "_services";
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { FormInput, FormAutocompleteGroupingRegion } from "_components/FormElements/FormInput";
import classnames from 'classnames';


const EventSubscription = (props) => {
    const redux_data = useSelector(state => state.subscription);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    // const toggle = () => setDropdownOpen(prevState => !prevState);
    const [eventRegisterAs, setEventRegisterType] = useState(null);
    let [packList, setPackList] = useState([]);
    const [packDisp, setPackDisp] = useState(false);
    const [activeTab, setActiveTab] = useState();



    const [state, setState] = useState({
        sellingCountries: [],
        sellingCountriesOption: [],
        targetCountries: [],
        targetCountriesOption: [],
        no_of_skus: null,

    });

    const { register, handleSubmit, control, errors, watch, formState } = useForm({
        mode: 'onBlur',
        //resolver: yupResolver(GeneralFormSchema),
    });

    const watchAll = watch(['no_of_skus']);//'tradeUrls',
    let [loading, setLoading] = useState(true);
    let [data, setData] = useState({ packageList: [], offers: [] });
    const [packageMaster, setPackageMaster] = useState([]);

    let userData = JSON.parse(localStorage.getItem('CHuser'));
    const eventDetails = useSelector(state => state.event)?.eventDetails;

    let dispatch = useDispatch();

    let eventId = eventDetails?.eventId ? eventDetails?.eventId : '0';

    const planData = [
        { spid: 201, plan: "Hosted Buyer", cost: 0, badge: "Free accommodation for 2 nights at the main event hotel", agent: "All meals and refreshments as per the agenda", space: "Shuttle bus service to/from Nice airport", shop: "Access to workshops", rib: "Discover", special: 'For qualified buyer and commitment to book and attend at least 10 one-to-one meetings' },
        // {spid:202,plan:"Invited Buyer",cost:990,badge:"Accommodation for 2 nights at the main event hotel",agent:"All meals and refreshments as per the agenda",space:"Shuttle bus service to/from Nice airport",meet:"Access to the 1 to 1 meeting system",shop:"Access to workshops",rib:"Package 1",special:'Attendance without any commitment regarding one-to-one meetings'},
        // {spid:203,plan:"Visiting Delegate",cost:1350,badge:"Visitor pass includes accommodation for 2 nights",agent:"All meals and refreshments as per the agenda",space:"Shuttle bus service to/from Nice airport",meet:"Access to the meeting system",shop:"Access to workshops",rib:"Package 2",special:"If you don't qualify as a buyer (ex: marketplace, Retail as a Service, sales agency...)"}
    ];

    let [packageId, setPackageId] = useState(null);
    let [pendingBadgecount, setPendingBadgecount] = useState(0);
    

    useEffect(() => {

        if (eventDetails)
            getPackageDetails();
        else
            history.push('/admin/events');
    }, [])

    const getPackageDetails = async () => {
        let userData = JSON.parse(localStorage.getItem('CHuser'));
        setLoading(true);
        let res = await commonService.getServices('channel/eventPackages/' + eventId).catch(err => {
            setLoading(false);
            ToastsStore.error('Something Went Wrong!');
            commonService.postService({ err: err.response.data.message, reqUrl: window.location.pathname, service: 'channel/eventPackages' }, '/error')
        });

        data.packageList = res.data.packageList.filter(x => !eventDetails.completedPackage.includes(x.packageId));
        data.offers = res.data.offers;
        data.hasPrimary = res.data.hasPrimary;

        if (userData.eventRegisterAs == 1) {
            //setPackDisp(false);
            const sellerPacks = res.data.packageList.filter(x => x.isSeller == 1);
            setPackList(sellerPacks);
        }
        else if (data.packageList.filter(x => x.isSeller == 1).length > 0 && data.packageList.filter(x => x.isBuyer == 1).length > 0) {

            setPackDisp(true);

            await commonService.getServices('setting/getUserProfile/' + userData?.channelId).then(res => {
                let selling = [];
                let target = [];

                state.sellingCountriesOption = res.data.regions1;
                selling = res.data.regions1;
                state.targetCountriesOption = res.data.regions;
                target = res.data.regions;

                setState({ ...state, targetCountriesOption: state.targetCountriesOption });
                setState({ ...state, sellingCountriesOption: state.sellingCountriesOption });

            }).catch(err => {
                setLoading(false);
                commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getUserProfile' }, '/error')
            })

        }
        else
            setPackList(data.packageList);

        setPackageMaster(res.data);

        setData({ ...data });
        setLoading(false);
        
        /// check any pending badges available
        let badgeCount = await commonService.getServices('channel/getPendingBadges/' + eventId).then(async res => {

            setPendingBadgecount(+res.data.no_of_access_badge - +res.data.count);
            return res;
        });
       

        // props.subscriptionRedux(plan,'PLAN');
        // props.subscriptionRedux(amount,'AMOUNT');
    }


    const selectPackage = (pack) => {
        console.log('secondary users', eventDetails, pack)   

        let appData =  {
                  "channelId": userData?.channelId,
                  "contact": userData?.contactId,               
                  "signUpStatusId": 15,
                  "fromEvent": userData?.channelId,
                  "eventId": eventDetails?.eventId,
                  "eventType": eventDetails?.type,
                  "packageId": pack?.packageId,
                  "packagePrice": pack?.price,
                  "paymentSplit": [
                      {
                          "channelId": userData.channelId,
                          "contactId": userData.contactId,
                          "eventId": eventDetails?.eventId,
                          "packageId": pack?.packageId,
                          "splitId": 1,
                          "paymentRemainderCount": 0,
                          "paymentMethod": 1,
                          "paymentStatus": '0',
                          "wireTransfer": "0",
                          "percentage": 100,
                          "name": eventDetails?.event_name,
                          "currCode": "EUR",
                          "description": eventDetails?.description,
                          "splitAmount":  pack?.price,
                          "packagePrice": pack?.price,
                          "totalAmount": pack?.price,
                          "discount": 0,
                          "actualAmount":  pack?.price,
                          "paidFlag": false,
                          "pennyInvoiceId": null
                      }
                  ]
                

              }
        console.log('secondary users pack', appData)
       
        setLoading(true);
        // if (eventDetails.viaEventCatalyst == '1') {
        let packDetails = {
            eventId: eventId,
            packageId: pack.packageId,
            price: pack.price,
            // additionalMembersCosting: pack.additionalMembersCosting,
            membersAllowed: +pack.no_of_access_badge,
            meetingsAllowed: +pack.no_of_meeting_allowed,
            packageName: pack.packageName,
            regTemplate: pack.template
            // marketingOptions: pack.marketingOptions,
            // packageOptions: pack.packageOptions
        };

        dispatch({ type: 'EVENT_USERS', data: { contacts: [userData.contactId] } });
        dispatch({ type: 'EVENT_PACK', data: { ...packDetails } });



        //hide after payment integration
        let payment = {
            sellerDetail: { targetCountries: state.targetCountries, no_of_skus: +state.no_of_skus },
            paymentStatus: (eventDetails.channel?.approvalStatus == '1' && eventDetails.channel?.paymentstatus == '1') ? '1' : '0',
            paymentMethod: null,
            paymentTransactionId: null,
            //isDelegate: (eventDetails.channel?.approvalStatus == '1' && eventDetails.channel?.paymentstatus == '1') ? true : false
        };


        eventService.eventRegistration(payment);


        //end
        // }
        // else {
        //     var userData = JSON.parse(localStorage.getItem('CHuser'));


        //     let packDetails = {
        //         eventId: eventId,
        //         packageId: pack.packageId,
        //         price: pack.price,              
        //         membersAllowed: +pack.no_of_access_badge,
        //         meetingsAllowed: +pack.no_of_meeting_allowed,
        //         packageName: pack.packageName               
        //     };

        //     dispatch({ type: 'EVENT_USERS', data: { contacts: [userData.contactId] } });
        //     dispatch({ type: 'EVENT_PACK', data: { ...packDetails } });

        //     const paymentDetails = {
        //         actualAmount: pack.price,
        //         package: [{ pacakgeId: pack.packageId, price: pack.price, packageName: pack.packageName }],
        //         channelId: userData.channelId,
        //         contactId: userData.contactId,
        //         finalAmount: pack.price,
        //         eventId: eventId,
        //         isPrimary: eventDetails?.channel?.contacts[0]?.eventParticipants.filter(p => p.eventId == eventDetails.eventId).length?false:true
        //     }

        //     console.log("eventpay:",paymentDetails)
        //     dispatch({ type: 'EVENT_PAYMENT', data: paymentDetails });
        //     // history.push('/admin/eventSubscription/'+eventId+'/eventUsers/');
        //     history.push('/admin/Subscription/EventPayment');
        // }



        if(appData && eventDetails.alreadyRegistered > 0){
            axios.put('/channelAdmin/channelApproval', appData).then(async res => {
                ToastsStore.success('Comple your payment to procees further');
                history.push('/admin/events');
            });
            
        }


    };

    let [isModalPopup, setModalPopup] = useState(false);
    let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });

    const confirmPopup = (res) => {
        // console.log("ress:",res)
        setModalPopup(false);
        selectPackage(res.value);
    }

    const closePopup = () => {
        setModalPopup(false);
    };

    const confirmPack = (pack) => {
        // console.log("targetCountry:",state.targetCountries)
        modalPopValue.message = <p>you have selected the package - <span style={{ color: '#11cdef' }}>{pack.packageName}</span>.</p>;
        modalPopValue.title = 'Package Selection';
        modalPopValue.second_message = <p>Do you want to proceed ? </p>;
        modalPopValue.value = pack;
        modalPopValue.popUpId = 1;
        setModalPopValue({ ...modalPopValue });
        setModalPopup(true);
    }

    const selectPack = (pack) => {
        console.log("addon:", pack)
    }

    const selectedItem = (gname, check, optionName, optionValue) => {
        let opt = [];
        // console.log("check:", check, gname);
        if (check === true) {
            state[optionName].map((item) => {
                // console.log("groupname:", item.groupname);
                if (item.groupname === gname || item.status === true) {
                    item.status = check;
                    opt.push(item);
                }
            });
        }
        if (check === false) {
            //   console.log("check:", check);
            state[optionName].map((item) => {
                if (item.groupname === gname && state[optionValue].length > 0)
                    item.status = check;
            });
            opt = state[optionValue].filter((item) => item.groupname !== gname);
            opt.map(option => option.status = true);
        }
        state[optionValue] = opt;
        setState({ ...state, [optionValue]: state[optionValue] });
        setState({ ...state, [optionName]: state[optionName] });

        if (optionValue === 'sellingCountries' && state.sellingCountries.length === 0)
            errors.selling = true;
        else if (optionValue === 'targetCountries' && state.targetCountries.length === 0)
            errors.target = true;

    }

    const handleChangeSelect = (input, value) => {

        if (input === 'targetCountries') {
            state.targetCountriesOption.map((item) => {
                if (value.length === 0)
                    item.status = false;
                else {

                    if (value.filter(e => e.childname == item.childname).length)
                        item.status = true;
                    else
                        item.status = false;
                }
            });

            value.map(option => option.status = true);
            state.targetCountries = value
            setState({ ...state });

            if (state.targetCountries.length === 0)
                errors.target = true;
        }

    }

    const handleChange = input => e => {

        setState({ ...state, [input]: e.target.value });


    }

    const setParticipant = (type) => {
        //1 - Seller 2 - Buyer        
        if (type == 1) {
            const packList = data.packageList?.filter(pack => pack.isSeller == 1);
            setEventRegisterType(1);
            setActiveTab('1');
            setPackList(packList);

        } else if (type == 2) {
            const packList = data.packageList?.filter(pack => pack.isBuyer == 1);
            setEventRegisterType(2);
            setPackList(packList);
            setActiveTab('2');
        }

    }



    let sellerLabels = <Row>
        <Col lg="12">
            <p className="mt-2 mb-2">
                Booth Size</p>
        </Col>
        <Col lg="12">
            <p className="mt-2 mb-2">No.of Meetings Allowed</p>
        </Col>
        <Col lg="12">
            <p className="mt-2 mb-2">No.of Access Badges Allowed
            </p>
        </Col>
        <Col lg="12">
            <p className="mt-2 mb-2">No.of Private Webinar
            </p>
        </Col>
        <Col lg="12">
            <p className="mt-2 mb-2">No.of Fireside Chat Allowed
            </p>
        </Col>
        <Col lg="12">
            <p className="mt-2 mb-2">Additional Members Costing
            </p>
        </Col>
        <Col lg="12">
            <p className="mt-2 mb-2">No.of Email Marketing
            </p>
        </Col>
        <Col lg="12">
            <p className="mt-2 mb-2">No.of Year CH Subscription
            </p>
        </Col>
    </Row>;

    let BuyerLabels = <Row>
        <Col lg="12">
            <p className="mt-2 mb-2">No.of Meetings Allowed</p>
        </Col>
        <Col lg="12">
            <p className="mt-2 mb-2">No.of Access Badges Allowed
            </p>
        </Col>
        <Col lg="12">
            <p className="mt-2 mb-2">Additional Members Costing
            </p>
        </Col>
    </Row>;

    return (
        <div className="main-content">
            <SimpleHeader location={props.match} />
            <div className="container">
                <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
                {loading ? <Spinner /> :
                    <>


                        {packDisp && <><br />
                            <div className="row">
                                <div className="eventsubs-tabs">
                                    <h2>Register Us</h2>
                                    {/* <Nav tabs>
        <NavItem> */}

                                    <div
                                        className={classnames('subs-button', { active: activeTab === '1' })}
                                        onClick={() => setParticipant('1')}
                                    >
                                        <div id="v" className="prof-span">
                                            <span id="ven" className="img-v"></span>

                                            Seller
                                        </div>
                                    </div>
                                    {/* </NavItem> */}
                                    {/* <NavItem> */}
                                    <div
                                        className={classnames('subs-button', { active: activeTab === '2' })}
                                        onClick={() => setParticipant('2')}
                                    >
                                        <div id="d" className="prof-span" >
                                            <span id="dis" class="img-d"></span>
                                            Buyer
                                        </div>
                                    </div>
                                    {/* </NavItem> */}
                                    {/* </Nav> */}

                                </div>
                            </div></>}

                        {(packDisp && eventRegisterAs == 1) && <><br />
                            <div className="row">
                                <div className=' selectprof container register_package'>
                                    <Card style={{ marginBottom: 0 }}>
                                        <CardHeader>
                                            <h3 className="text-center" style={{ fontWeight: 'bold' }}>Update the following information as you register as seller<span id='algorithm'><i class="far fa-handshake didy"></i></span></h3>
                                            {/* <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h3> */}
                                            {/* <p className="text-center" >Update the target locations as you register as seller</p> */}
                                        </CardHeader>
                                        <CardBody>

                                            <div className="form-group">
                                                <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="target" name="target" ref={register} value={state.targetCountries.length ? state.targetCountries[0].childname : ''} />
                                                <FormAutocompleteGroupingRegion
                                                    input="targetCountries"
                                                    id="TargetCountries"
                                                    label='Target Locations *'
                                                    name="TargetCountries"
                                                    Options={state.targetCountriesOption}
                                                    placeholder="Search"
                                                    errMessage='Please choose Target Locations'
                                                    error={!errors.target || state.targetCountries.length ? false : true}
                                                    optionName='targetCountriesOption'
                                                    optionValue='targetCountries'
                                                    defaultValue={state.targetCountries}
                                                    value={state.targetCountries}
                                                    // loading={true}
                                                    selectedItem={selectedItem}
                                                    handleChangeSelect={handleChangeSelect}
                                                    control={control}
                                                    register={register}
                                                    isGroup='0'
                                                />
                                            </div>

                                            <div className="col-lg-12" style={{ padding: 0 }}>
                                                <h4>No of SKU's *</h4>
                                                <h5  >Enter the number of products that you handle</h5>
                                                <div className="form-group">
                                                    <FormInput
                                                        id="no_of_skus"
                                                        type="number"
                                                        name="no_of_skus"
                                                        label="No of SKU's *"
                                                        InputProps={{
                                                            inputProps: {
                                                                max: 100, min: 100
                                                            }
                                                        }}
                                                        onWheel={(event) => event.target.blur()}
                                                        onChange={handleChange('no_of_skus')}
                                                        control={control}
                                                        register={register}
                                                        defaultValue={state.no_of_skus}
                                                        value={watchAll.no_of_skus}
                                                        error={errors.no_of_skus}
                                                    />
                                                </div>
                                            </div>


                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </>}


                        {((packDisp && eventRegisterAs != null) || !packDisp) &&

                            <PerfectScrollbar>
                                <div className="container register_package">
                                    <h2 style={{ margin: 0 }}>Choose Your Package</h2>
                                    <div className="row w-100" style={{ justifyContent: "center" }}>
                                        <div className="col-lg-12 prof-001" style={{ height: "auto" }}>
                                            <div class="container">
                                                <div id="event" class="prof-row1">
                                                    {data.hasPrimary == null ? '' :
                                                        <div style={{ justifyContent: "center", padding: "10px" }}>We have already registered 1 attendee for your company <strong>{data.hasPrimary}</strong> , you can select between the available packages below. </div>}
                                                      { (pendingBadgecount > 0) && 
                                                         <p>
                                                            You channel Also have remaining deligate option : {pendingBadgecount}
                                                         </p>}
                                                    {packList.map((x, i) => {
                                                        let offers = data.offers?.filter(off => off.offerType == 1 && x.offerIds.split(',').includes(off.couponId.toString()));
                                                        return (
                                                            <a className='curs-point' key={'curs' + x.packageId}>
                                                                <div onClick={() => confirmPack(x)} key={x.packageId}>
                                                                    <div className={packageId == x.packageId ? 'bg-light pt-2 pb-2 mb-4' : ''}><Row><Col className='package_column' lg='12'>
                                                                        <div style={parseInt(x.price) == 990 ? { height: '277px' } : { height: '277px' }} className='rotated center'><h1 className='h4 text-center text-uppercase '>{x.packageName}</h1></div>
                                                                        {/* </Col> */}
                                                                        {/* <Col lg='11'> */}
                                                                        <div className="package_card" style={{ width: '95%' }}>
                                                                            {/* <Card className={packageId == x.packageId ? 'm-0' : ''} style={parseInt(x.price) == 0 ? { minWidth: '100%' } : { minWidth: '100%' } && parseInt(x.price) == 990 ? { minWidth: '100%' } : { minWidth: '100%' }}> */}
                                                                            <Card className={packageId == x.packageId ? 'm-0' : ''} style={{ width: '95%' }}>
                                                                                <CardBody>
                                                                                    <div className='d-flex flex-wrap justify-content-between'>
                                                                                        {/* <div class="ribbon blue"><span>{x.rib}</span></div> */}
                                                                                        <div className="col-lg-8">
                                                                                            <p class=" text-left text-primary" dangerouslySetInnerHTML={{ __html: x.description }} />
                                                                                            {/* <p class=" text-left text-primary"> <i class="far fa-hand-point-right"></i> {x.description}</p> */}
                                                                                            {/* <p class="description text-left"> <i class="far fa-hand-point-right"></i> {x.badge}</p>
                            <p class="description text-left"> <i class="far fa-hand-point-right"></i> {x.agent}</p>
                            <p class="description text-left"> <i class="far fa-hand-point-right"></i> {x.space}</p> */}
                                                                                            {/* {x.meet && <p class="description text-left"> <i class="far fa-hand-point-right"></i> {x.meet}</p>} */}
                                                                                            {/* <p class="description text-left"> <i class="far fa-hand-point-right"></i> {x.description}</p> */}
                                                                                        </div>
                                                                                        <div className="col-md-4"><h1 class="h3 text-info text-center"><span className='mr-2 display-1 font-weight-light'> {parseInt(x.price)}</span><i class="fas fa-euro-sign"></i></h1></div>

                                                                                        {offers.length > 0 && offers?.map(o =>

                                                                                            <div><p class=" text-left text-primary"><b>{o.fullDesc}</b></p></div>
                                                                                        )}
                                                                                    </div>



                                                                                    {/* addon listing */}
                                                                                    {/* {packageMaster.filter(ad => ad.packageType == 2 && ad.belongsTo==x.packageId).length>0 && 
                                                                <Card className="stickyPay">
        <CardBody>
        <Row>
            <Col lg="3">
            <h1 className="text-left planwidth">Select Your Premium <span className="planBorder">Plan</span></h1>
            </Col>

            {
                packageMaster.filter(ad => ad.packageType == 2 && ad.belongsTo==x.packageId).map(x => (
            <Col lg="3" className="text-center" key={x.packageName}>
                <h2 className="mb-3">{x.packageName}</h2>
            <button onClick={() => selectPack(x)} type="button" className="btn bt-pri"><h3 className="pre-rate">{x.price}<i class="fas fa-euro-sign"></i></h3></button>
            </Col>
                ))
            }
        </Row>
        </CardBody>
    </Card> } */}


                                                                                </CardBody>
                                                                            </Card>
                                                                        </div>
                                                                    </Col></Row></div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })}
                                                    {/* <Row className='d-flex justify-content-center'>
            <Col lg="4" onClick={() => selectPackage(201)} className={packageId==201 ? 'bg-light' : ''}>
            <a href="#">
            <Card className="card-lift--hover border-0 imprt">
            <div class="ribbon blue"><span>Discover</span></div>
                <CardBody className="py-5 card-body">
                        <h1 className='h4 text-info text-center text-uppercase'>Hosted buyer</h1>
                        <h1 class="h3 text-info text-center"><span className='mr-2 display-1 font-weight-light'>8000</span><i class="fas fa-euro-sign"></i></h1>
                        <p class="description text-center">BADGES: <span>1 pass</span></p>
                        <p class="description text-center"><span>Up to 15 pre-scheduled 1-to-1 meetings</span></p>
                        <p id='meet' class="description text-center">MEETING SPACE: <span>Meeting table (1,8m x 0,8m) with 2 chairs</span></p>
                        <UncontrolledTooltip target='meet' placement='bottom'> Meeting table (1,8m x 0,8m) with 2 chairs </UncontrolledTooltip>
                </CardBody >
            </Card>
            </a>
            </Col>

            <Col lg="4" onClick={() => selectPackage(202)} className={packageId==202 ? 'bg-light' : ''}>
            <a href="#">
            <Card className="card-lift--hover border-0 imprt">
            <div class="ribbon blue"><span>Package 1</span></div>
                <CardBody className="py-5 card-body">
                        <h1 className='h4 text-info text-center text-uppercase'>Invited buyer</h1>
                        <h1 class="h3 text-info text-center"><span className='mr-2 display-1 font-weight-light'>13000</span><i class="fas fa-euro-sign"></i></h1>
                        <p class="description text-center">BADGES: <span>2 passes</span></p>
                        <p class="description text-center"><span>Up to 30 pre-scheduled 1-to-1 meetings</span></p>
                        <p id='meet1' class="description text-center ovr-flw">MEETING SPACE: <span>7.5sqm exhibition stand including carpet,stand partition walls, lighting, 1 large meeting table, 4 chairs and 1 display table</span></p>
                        <UncontrolledTooltip target='meet1' placement='bottom'> 7.5sqm exhibition stand including carpet,stand partition walls, lighting, 1 large meeting table, 4 chairs and 1 display table </UncontrolledTooltip>
                </CardBody >
            </Card>
            </a>
            </Col>

            <Col lg="4" onClick={() => selectPackage(203)} className={packageId==203 ? 'bg-light' : ''}>
            <a href="#">
            <Card className="card-lift--hover border-0 imprt">
            <div class="ribbon blue"><span>Package 2</span></div>
                <CardBody className="py-5 card-body">
                        <h1 className='h4 text-info text-center text-uppercase'>Visiting delegate</h1>
                        <h1 class="h3 text-info text-center"><span className='mr-2 display-1 font-weight-light'>18000</span><i class="fas fa-euro-sign"></i></h1>
                        <p class="description text-center">BADGES: <span>3 passes</span></p>
                        <p class="description text-center"><span>Up to 45 pre-scheduled 1-to-1 meetings</span></p>
                        <p id='meet2' class="description text-center ovr-flw">MEETING SPACE: <span>Hospitality Suite in the Hospitality Zone & 7.5sqm exhibition stand OR 15sqm double stand. Furniture provided: 2 large meeting tables, 8 chairs, 2 display table</span></p>
                        <UncontrolledTooltip target='meet2' placement='bottom'>Hospitality Suite in the Hospitality Zone & 7.5sqm exhibition stand OR 15sqm double stand. Furniture provided: 2 large meeting tables, 8 chairs, 2 display table</UncontrolledTooltip>
                </CardBody >
            </Card>
            </a>
            </Col>
            </Row> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </PerfectScrollbar>}
                    </>
                }



            </div>
        </div>

    );
};

export default EventSubscription; //AddgeneralForm;
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Container } from "reactstrap";
import emealogo from 'assets/img/guestpage/LOGO_CHANNEL_SUMMIT_EMEA.png'
import ifalogo from 'assets/img/guestpage/Logo_Global_Retail_IFA.png'
import apaclogo from 'assets/img/guestpage/Logo_ChannelConnect APAC.png'

import EventTabs from './EventTabs'
// import RegisterEve from './RegisterEve';
import Vendorbuyerlist from './Vendorbuyerlist';
import { ManageUser } from "views/pages/Events/ManageUser";
import SimpleHeader from "components/Headers/SimpleHeader";
import Eventtravel from './Eventtravel';
import { useSelector } from 'react-redux';
import EventAgenda from './EventAgenda';
import { MeetingScheduler } from './MeetingScheduler';
// import { CodeOutlined } from '@material-ui/icons';
import EventMeetings from './EventMeetings';
import MySchedule from './MySchedule';
import EventAwards from './EventAwards';
import { Fallback } from '_components/ErrorElements/FallBack';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";

const Eventlistingwrapper = (props) => {


    const userData = JSON.parse(localStorage.getItem('selectCHUser'));
    if(userData == null){
        userData = JSON.parse(localStorage.getItem('CHuser'));
    }
    let eventDetails = useSelector(state => state.event)?.eventDetails;
    // let eventPackage = useSelector(state => state.event)?.eventPackage;
    let [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(false);

    const getTabs = (id) => {
        switch (id) {
            case '1':
                return <ManageUser />;
            case '2':
                return <Eventtravel eventId={eventDetails?.eventId} />;
            case '3':
                //temp work for current event only
                return <Vendorbuyerlist id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} />
            case '4':
                return <EventAgenda eventId={eventDetails?.eventId} contactId={userData.contactId} />
            case '5':
                return <EventMeetings />
            case '6':
                return <EventAwards />
            case '7':
                return <MeetingScheduler id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} />
            case '8':
                return <MySchedule id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} />
            default:
                return eventDetails?.eventStatus == 3 ? <Vendorbuyerlist id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} /> : <ManageUser />
        }
    }

    const [activeTab, setActiveTab] = useState(props.match.params ? props.match.params.id : '1');

    const changeTab = (id) => {
        setActiveTab(id);
    }


    const errorHandler = (error, errorInfo) => {
        console.log('Logging:', error, errorInfo);

    }

    const getToken = () => {
        let req = {
            url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
        }
        return commonService.postService(req, 'account/eventcatalystToken').then(res => {
            if (res.data?.error) {
               
                // ToastsStore.warning('Something went wrong'); 
            } else {
                localStorage.setItem('eventcatalyst_token', JSON.stringify(res.data));
            }
            return JSON.stringify(res.data)
        }).catch(err => {
            setLoading(false);
            console.log('err', err)
        })
    }

    const eventRegister = () => {
        var userData = JSON.parse(localStorage.getItem('CHuser'));
        return new Promise((resolve) => {
            commonService.getServices('channel/eventRegister/' + userData.contactId).then(res => {
                profileData = res.data;
                setProfileData(profileData)
                console.log('res.data', res.data);
                resolve(true);
            }, err => {
                resolve(false);
                console.log('err', err);
            });
        })
    }


    const openCatalyst = async () => {

        setLoading(true);
        var userData = JSON.parse(localStorage.getItem('CHuser'));
       
        if (!localStorage.getItem('eventcatalyst_token')) {
            await getToken();
        }
        await eventRegister();
        let dec = profileData.length > 0 ? profileData[0].detailDesc : null
        if ((dec === null) || (dec === '') || !dec) {
            dec = null;
        } else {
            dec = dec.toString();
            dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
        }
        let data = {
            participantId: parseInt(userData.contactId),
            eventId: eventDetails.event_catalyst_id,
            companyId: parseInt(userData.channelId),
            existingCategory: profileData.length > 0 ? profileData[0].ExistingCategory ? profileData[0].ExistingCategory.replace(/,/g, "|") : null : null,
            interestedCategory: profileData.length > 0 ? profileData[0].IntrestedCategory ? profileData[0].IntrestedCategory.replace(/,/g, "|") : null : null,
            profileType: profileData.length > 0 ? profileData[0].ProfileType : null,
            retailCustomerProfile: profileData.length > 0 ? profileData[0].Retail_Customer_Profile ? profileData[0].Retail_Customer_Profile.replace(/,/g, "|") : null : null,
            detailDesc: dec,
            password: profileData.length > 0 ? profileData[0].password : null
        }

        let body = {
            token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
            url: process.env.REACT_APP_EVENTCATALYST_API + 'api/manage',
            body: data
        }
        commonService.postService(body, 'account/postEventRegister').then(res => {
            setLoading(false);
            window.open(res.data.pageUrl, '_blank');
        });



    }



    return (
        <>
        {loading ? <Spinner /> :
        <>
            <SimpleHeader location={props.match} />
            <div id="listing" className="main-content">
                {eventDetails?.eventId==3 && 
                  <div className='text-center'>
                  <img src={emealogo} width={130}  alt=''/>
            <h1 className='text-center mt-3 mb-3'>Channel Summit Details</h1>
              </div>}

              {eventDetails?.eventId==4 && 
                  <div className='text-center'>
                  <img src={ifalogo} width={130} alt='' />
            <h1 className='text-center mt-3 mb-3'>Global Retail Connect at IFA</h1>
              </div>}
              {eventDetails?.eventId==5 && 
                  <div className='text-center'>
                  <img src={apaclogo} width={130} alt='' />
            <h1 className='text-center mt-3 mb-3'>Channel Connect 1-to-1 EMEA-APAC</h1>
              </div>}
              
                {userData.channelTypeId != 2 ?
                    <p className='text-center mt-3 mb-3' style={{ fontSize: '13px' }}><b>IMPORTANT:</b> The vendor list is for review only, not for sending meeting requests.</p>
                    : <p className='text-center mt-3 mb-3' style={{ fontSize: '13px' }}><b>IMPORTANT:</b>The Meeting Planner is open, send your meeting requests now. click <a onClick={() => openCatalyst()} style={{cursor:"pointer",fontWeight:"bold",textDecoration:"underline",color:"#2c9db7"}}>HERE</a> to jump to the Meeting Planner </p> }
                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <Container className="col-12 list-tab" fluid>


                        <EventTabs activeTab={activeTab} eventDetails={eventDetails} changeTab={changeTab} channelTypeId={userData.channelTypeId} />


                        {getTabs(activeTab)}

                        {/* <RegisterEve/> */}
                        {/* <ManageUser/> */}
                        {/* <Vendorbuyerlist/> */}
                        {/* <Eventtravel/> */}
                    </Container>
                </ErrorBoundary>
            </div>
            </>}
        </>
    );
};

export default Eventlistingwrapper;
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { signupschema } from './Validation';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
export default function Step1(props) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    let [channelTypeId, setChannelTypeId] = useState(props.values.channelTypeId);

    const { register, handleSubmit, control, errors } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(signupschema)
    });

    const onSubmit = data => {
        if (data) {
            console.log("data:", data);
        }
    }
    const active = event => {
        if ((event.target.id == 'ven' || event.target.id == 'dis' || event.target.id == 'ret' || event.target.id == 'res')) {


            document.getElementById('v').style.color = '#999b9c';
            document.getElementById('d').style.color = '#999b9c';
            document.getElementById('r').style.color = "#999b9c";
            document.getElementById('re').style.color = "#999b9c";

            if (document.getElementById("ven").classList.contains("img-v-ac")) {
                document.getElementById("ven").classList.remove("img-v-ac");
                setChannelTypeId(2);
            }
            if (document.getElementById("dis").classList.contains("img-dac")) {
                document.getElementById("dis").classList.remove("img-dac");
                setChannelTypeId(3);
            }
            if (document.getElementById("ret").classList.contains("img-rac")) {
                document.getElementById("ret").classList.remove("img-rac");
                setChannelTypeId(4);
            }
            if (document.getElementById("res").classList.contains("img-res")) {
                document.getElementById("res").classList.remove("img-res");
                setChannelTypeId(5);
            }
            if (event.target.id == 'ven') {
                document.getElementById(event.target.id).classList.add('img-v-ac');
                document.getElementById('v').style.color = '#3f51b5';
                setChannelTypeId(2);
            } else if (event.target.id == 'dis') {
                document.getElementById(event.target.id).classList.add('img-dac');
                document.getElementById('d').style.color = '#3f51b5';
                setChannelTypeId(3);
            } else if (event.target.id == 'ret') {
                document.getElementById(event.target.id).classList.add('img-rac');
                document.getElementById('r').style.color = "#3f51b5";
                setChannelTypeId(4);
            }
            else if (event.target.id == 'res') {
                document.getElementById(event.target.id).classList.add('img-res');
                document.getElementById('re').style.color = "#3f51b5";
                setChannelTypeId(5);
            }
        }
        //event.target.id.classList.add("g-sidenav-hidden");
    }
    // useEffect(() => {
    //     const header = document.getElementsByClassName("prof-row1");
    //     const ac = header.getElementsByClassName("ac");
    //     for (const i = 0; i < ac.length; i++) {
    //       ac[i].addEventListener("click", function() {
    //         const current = document.getElementsByClassName("activeac");
    //       current[0].className = current[0].className.replace(" activeac", "");
    //       this.className += " activeac";
    //       });
    //     }
    // }, []); 

    const { assignValues } = props;

    const next = () => {
        if (channelTypeId != 0) {

            if (channelTypeId == 4) {
                props.values.isOEM = '1';
                assignValues({ channelTypeId: channelTypeId, isOEM: '1' });
            }
            else if (channelTypeId == 5) {
                props.values.isOEM = '2';
                assignValues({ channelTypeId: channelTypeId, isOEM: '2' });
            }
            else if (channelTypeId == 2 || channelTypeId == 3) {
                props.values.isOEM = '0';
                assignValues({ channelTypeId: channelTypeId, isOEM: null });
            }

            else
                assignValues({ channelTypeId: channelTypeId });


            props.getSignup(channelTypeId);

        }
    }

    useEffect(() => {

        if (props.values?.participantType == 1)
            document.getElementById('v').style.color = '#999b9c';
        if (props.values?.participantType == 2) {
            document.getElementById('d').style.color = '#999b9c';
            document.getElementById('r').style.color = "#999b9c";
        }

        if (channelTypeId == 2) {
            document.getElementById("ven").classList.remove("img-v-ac");
            document.getElementById("ven").classList.add('img-v-ac');
            document.getElementById('v').style.color = '#3f51b5';
        }
        if (channelTypeId == 3) {
            document.getElementById("dis").classList.remove("img-dac");
            document.getElementById("dis").classList.add('img-dac');
            document.getElementById('d').style.color = '#3f51b5';
        }
        if (channelTypeId == 4) {
            document.getElementById("ret").classList.remove("img-rac");
            document.getElementById("ret").classList.add('img-rac');
            document.getElementById('r').style.color = "#3f51b5";
        }

    }, []);

    const setChannelType = (type) => {
        channelTypeId = type;
        setChannelTypeId(channelTypeId);
        next();
    }


    return (
        <div>
            <div className="main-content">
                <div className="container">
                    <div className="row">
                    {/* {(props.values?.participantType == 1 || props.values?.participantType == 2) &&                                  */}
                                <span style={{'color':'black'}}>If you already have an existing account on ChannelHub,<a href="/login/1"> click here</a> to login</span>
                                {/* } */}
                        <div className="col-lg-12 prof-001">
                        
                            <div class="container" style={{padding:0}}>

                           
                                <div id="pro" class="row prof-row1">
                                    {/* <div class="col-sm-1 prof-002 ">
                      <div class="img-ta"/>
                      <span className="prof-span">VENDOR</span>
                  </div>
                  <div class="col-sm-1 prof-002">

                  <div class="img-ta1"/>
                      <span className="prof-span">DISTRIBUTER</span>
                  </div>
                  <div class="col-sm-1 prof-002">
                  <div class="img-ta2"/>

                      <span className="prof-span">RETAILER</span>
                  </div>
                  <div class="col-sm-1 prof-002">
                  <div class="img-ta3"/>
    
                      <span className="prof-span">TEAM HEAD</span>
                  </div>
                  <div class="col-sm-1 prof-002">
                  <div class="img-ta4"/>
                      <span className="prof-span">VENDOR</span>
                  </div>
                  <div class="col-sm-1 prof-002">
                  <div class="img-ta5"/>
    
                      <span className="prof-span">VENDOR</span>
                  </div>
                  <div class="col-sm-1 prof-002">
                  <div class="img-ta6"/>
    
                      <span className="prof-span">VENDOR</span>
                  </div> */}
                                    {/* <div onClick={active} class="col-sm-3 prof-003">
                  <div id="ven" class="img-v"></div>
                  <span id="v" className="prof-span">VENDOR</span>
                  </div>
                  <div onClick={active}  class="col-sm-3 prof-003">
                  <div id="dis" class="img-d"></div>
                  <span id="d" className="prof-span">DISTRIBUTOR</span>
                  </div>
                  <div onClick={active} class="col-sm-3 prof-003">
                  <div id="ret" class="img-r"></div>
                  <span id="r" className="prof-span">RETAILER</span>
                  </div>
                  <div onClick={active} class="col-sm-3 prof-003">
                  <div id="res" class="img-re"></div>
                  <span id="re" className="prof-span">RESELLER</span>
                  </div> */}
                                    <div className='col-lg-3'></div>
                                    <div className='col-lg-6 col-sm-12 selectprof'>
                                  <h5 className='mob-signhead' style={{display:'none'}}>Select Profile</h5>

                                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                            <DropdownToggle caret>
                                                {channelTypeId == '2' ? 'VENDOR' : channelTypeId == '3' ? 'DISTRIBUTOR' : channelTypeId == '4' ?
                                                    'RETAILER' : channelTypeId == '5' ? 'RESELLER' : 'Select Your Profile'}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {(props.values?.participantType == 1 || props.values?.participantType === null) && <DropdownItem onClick={() => setChannelType('2')}>
                                                    <div id="ven" class="img-v"><span id="v" className="prof-span">VENDOR</span></div>
                                                </DropdownItem>}
                                                {(props.values?.participantType == 2 || props.values?.participantType === null) && <>
                                                    <DropdownItem onClick={() => setChannelType('3')}>
                                                        <div id="dis" class="img-d"><span id="d" className="prof-span" >DISTRIBUTOR</span></div>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setChannelType('4')}>
                                                        <div id="ret" class="img-r"><span id="r" className="prof-span" >RETAILER</span></div>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setChannelType('5')}>
                                                        <div id="res" class="img-re"><span id="re" className="prof-span" >RESELLER</span></div>
                                                    </DropdownItem>
                                                </>}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    <div className='col-lg-3'></div>
                                </div>                              

                            </div>
                        </div>
                    </div>

                    

                </div>
                {/* <button  className="btn btn-primary ch-btn-blue right-but" variant="contained" color="primary" onClick={next}>
            Next
          </button>  */}
            </div>
        </div>
    )
}




import React, { useEffect, useState, useMemo } from "react";
import { Card, CardBody, FormGroup, Form, Container, Col, Row, Table, Button } from "reactstrap";
import { commonService } from '_services/common.service';
import { history } from '../../../_helpers';
import { ToastsContainer, ToastsStore } from 'react-toasts';
const NewSub = (props) => {



  return (
    <div className="main-content" id="NewSub">
      {/* <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/> */}
      <div className="mt-5 container-fluid">
        <Card className="mb-0 pt-4 text-center">
          {/* <h1 className=" d-inline-block mr-3 pb-2">PLAN AND PRICING</h1> */}
        </Card>
        <Table responsive className="mt--2">
          <thead>
          <tr>
              <td ><h1 className=" d-inline-block mr-3 mt-6">PLAN AND PRICING</h1></td>
              <td style={{position:'relative'}}>        <span className="pricing-price">49€/Month</span> <span className="bi-text" style={{position:'absolute'}}><i>* Billed Annually</i></span><br/>
              <a href="#/" className="pricing-button is-featured">Subscribe</a></td>

              <td> <span className="pricing-price text-uppercase">Free</span> <br/>
              <a href="#/" className="pricing-button">Subscribe</a></td>

            </tr>
           


            {/* <tr>

              <th>  <h2 className="pricing-header">Price</h2></th>
              <th>  <h2 className="pricing-header">Premium Plan</h2></th>
              <th>  <h2 className="pricing-header">Free</h2></th>
            </tr> */}
          </thead>
          <tbody>

            {/* Commeted for new changes propsed by farouk 11062024 */}

            {/* <tr>

              <td> <p className="font-weight-600 titl">Your Visibility
              </p> </td>
              <td><p className="font-weight-600"> &nbsp;</p> </td>
              <td><p className="font-weight-600">&nbsp;</p> </td>
            </tr>
           
           
            <tr>

              <td><p>Number of Product sheets</p> </td>
              <td><p>25</p></td>
              <td><p>5</p></td>
            </tr>

            <tr>

<td><p>Your Product listing on Product catalogue page</p> </td>
<td><p>yes</p></td>
<td><p>yes</p></td>
</tr>
<tr>
              <td><p>Your company highlighted in Featured Brands on home page</p> </td>
              <td><p>yes for 1 week minimum</p></td>
              <td><p>Random</p></td>

            </tr>
            <tr>

              <td><p>Your Product highlighted on Channel Home Page</p> </td>
              <td><p>3</p></td>
              <td><p>Random</p></td>
            </tr>
          

            <tr>

<td> <p>Your company profile visibility on our Public web site</p> </td>
<td><p>yes</p></td>
<td><p>yes</p></td>
</tr> */}

          
            {/* <tr>

              <td><p>Your Product highlighted on CH social media</p> </td>
              <td><p>On Random basis</p></td>
              <td><p>1 product per month</p></td>
            </tr> */}
            {/* <tr>

              <td><p>Your product included in email suggestions to buyers</p> </td>
              <td><p>On Random basis</p></td>
              <td><p>3 per month</p></td>
            </tr>
            <tr>

              <td><p>Social Sharing: share your CH profile on your social media</p> </td>
              <td><p>yes</p></td>
              <td><p>yes</p></td>
            </tr>
            <tr>

              <td><p>Number of Product sheets</p> </td>
              <td><p>5</p></td>
              <td><p>25</p></td>
            </tr> */}
            <tr>

              <td> <p className="font-weight-600 titl">Lead Generation
              </p> </td>
              <td><p className="font-weight-600">&nbsp;</p> </td>
              <td><p className="font-weight-600">&nbsp;</p> </td>
            </tr>
            <tr>

              <td><p>Buyers notified when you add them to your favourites</p> </td>
              <td><p>Yes</p></td>
              <td><p>No</p></td>
            </tr>
            <tr>

              <td><p>Save profiles to your favourites</p> </td>
              <td><p>Yes</p></td>
              <td><p>No</p></td>
            </tr>
            <tr>

              <td><p>Suggestion of buyers</p> </td>
              <td><p>Yes</p></td>
              <td><p>No</p></td>
            </tr>
        
            <tr>

              <td><p>Exclusive access to our Channelub member's profiles (distributors, resellers, retailers)</p> </td>
              <td><p>Yes</p></td>
              <td><p>No</p></td>
            </tr>
            <tr>

              <td><p>Incoming leads from Buyers</p> </td>
              <td><p>Yes, Unlimited</p></td>
              <td><p>1 Free</p></td>
            </tr>



            {/* Commeted for new changes propsed by farouk 11062024 */}

            {/* <tr>

<td><p className="font-weight-600 titl">Online Events</p> </td>
<td><p className="font-weight-600">&nbsp;</p> </td>
<td><p className="font-weight-600">&nbsp;</p> </td>
</tr>
<tr>

<td><p>Unlimited access to all Buyer Insider Session</p> </td>
<td><p>Yes</p></td>
<td><p>Limited to 2</p></td>
</tr>
<tr>

<td><p>Send meeting requests to Buyers presenting at each session</p> </td>
<td><p>Yes</p></td>
<td><p>No</p></td>
</tr>
<tr>

<td><p>Number of meetings included</p> </td>
<td><p>10</p></td>
<td><p>0</p></td>
</tr>
<tr>

<td><p>Access to recording of all Buyers presentation</p> </td>
<td><p>Yes</p></td>
<td><p>No</p></td>
</tr>
<tr>

<td><p>Additional meetings</p> </td>
<td><p>100 €</p></td>
<td><p>-</p></td>
</tr> */}

            <tr>

              <td><p className="font-weight-600 titl">Your Online Performance tool</p> </td>
              <td><p className="font-weight-600">&nbsp;</p> </td>
              <td><p className="font-weight-600">&nbsp;</p> </td>
            </tr>
            <tr>

              <td><p>Your company added in "Follow this brand" on buyer side</p> </td>
              <td><p>Yes</p></td>
              <td><p>No</p></td>
            </tr>
            <tr>

              <td><p>Full Dashboard with identity of profile viewers and product viewers</p> </td>
              <td><p>Yes</p></td>
              <td><p>No</p></td>
            </tr>
            <tr>

              <td><p className="font-weight-600 titl">Other features</p> </td>
              <td><p className="font-weight-600">&nbsp;</p> </td>
              <td><p className="font-weight-600">&nbsp;</p> </td>
            </tr>
            <tr>

<td><p>Dedicated account Manager</p> </td>
<td><p>No</p></td>
<td><p>yes</p></td>
</tr>
            <tr>

              <td><p>Number of users who can receive leads</p> </td>
              <td><p>10</p></td>
              <td><p>1</p></td>
            </tr>
          
            <tr>
              <td></td>
              <td>        <span className="pricing-price">49€/Month</span><br/> <span><i>* Billed Annually</i></span><br/></td>

              <td> <span className="pricing-price text-uppercase">Free</span> </td>

            </tr>
            <tr>
              <td></td>
              <td> <a href="#/" className="pricing-button is-featured">Subscribe</a></td>

              <td><a href="#/" className="pricing-button">Subscribe</a></td>
            </tr>
          </tbody>
        </Table>
      </div>


    </div>

  );
};



export default NewSub;
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { signupschema } from './Validation';
import { Card, CardBody, Row, Col, UncontrolledTooltip } from "reactstrap";
import EventPackageOptions from "../Channel-Listing/EventPackageOptions";
import PerfectScrollbar from 'react-perfect-scrollbar'
import axios from 'axios';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


export default function Step6(props) {

    const [loading, setLoading] = useState(false);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    var planData = [
        { spid: 201, plan: "Hosted Buyer", cost: 0, badge: "Free accommodation for 2 nights at the main event hotel", agent: "All meals and refreshments as per the agenda", space: "Shuttle bus service to/from Nice airport", shop: "Access to workshops", rib: "Discover", special: 'For qualified buyer and commitment to book and attend at least 10 one-to-one meetings' },
        // {spid:202,plan:"Invited Buyer",cost:990,badge:"Accommodation for 2 nights at the main event hotel",agent:"All meals and refreshments as per the agenda",space:"Shuttle bus service to/from Nice airport",meet:"Access to the 1 to 1 meeting system",shop:"Access to workshops",rib:"Package 1",special:'Attendance without any commitment regarding one-to-one meetings'},
        // {spid:203,plan:"Visiting Delegate",cost:1350,badge:"Visitor pass includes accommodation for 2 nights",agent:"All meals and refreshments as per the agenda",space:"Shuttle bus service to/from Nice airport",meet:"Access to the meeting system",shop:"Access to workshops",rib:"Package 2",special:"If you don't qualify as a buyer (ex: marketplace, Retail as a Service, sales agency...)"},
        // {spid:204,plan:"Extra Delegate Shared Room",cost:490,badge:"2 nights accommodation at Fairmont in shared room  (nights of 19 & 20 May)",agent:"All meals as per event agenda",space:"Access to workshops and activities",meet:"Airport transfers",shop:"",rib:"",special:""},
        // {spid:205,plan:"Extra Delegate Single Room",cost:900,badge:"2 nights accommodation at Fairmont in shared room  (nights of 19 & 20 May)",agent:"All meals as per event agenda",space:"Access to workshops and activities",meet:"Airport transfers",shop:"Access to workshops",rib:"",special:""},
    ];
    const { channelTypeId, packageId, selectedMarketingOptions, selectedPackageOptions, packages, eventRegistration, eventId } = props.values;

    const { assignValues, handleChangeSelect, packageList } = props;

    const [message, setMessage] = useState();
    let [packList, setPackList] = useState([]);

    const [eventRegisterAs, setEventRegisterType] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [packError, setPackError] = useState(false);
    const [packFull, setPackFull] = React.useState(false);

    const [data, setData] = useState({
        selectedMarketingOptions,
        selectedPackageOptions,
        packageOptions: [],
        marketingOptions: [],
    });

    const next = () => {
        if (packageId) {
            assignValues({ packageId: packageId,eventRegisterAs:eventRegisterAs });
        } else if (!packFull) {
            setPackError(true);
        } else {
            assignValues({ packageId: 0,eventRegisterAs:eventRegisterAs });
        }
    }


    if (eventRegistration) {
        planData = planData.filter(x => [204, 205].includes(x.spid));
    } else {
        planData = planData.filter(x => ![204, 205].includes(x.spid));
    }

    useEffect(() => {



        let NO_SEATS_FLAG = true; let regProcess; let completedPackage = [];
        for (let user = 1; user <= 2; user++) {
            for (let pack = 1; pack <= 2; pack++) {
                let currentPack = packageList.list.filter(x => x.packageUser == user && x.packageType == pack)
                if (currentPack.length) {
                    if (+currentPack[0].registeredCount == 0) {
                        NO_SEATS_FLAG = false;
                        regProcess = 1;
                        //move to subscription page with package selection
                    }
                    else if (+currentPack[0].registeredCount > 0 && +currentPack[0].registeredCount < +currentPack[0].no_of_access_badge) {
                        NO_SEATS_FLAG = false;
                        regProcess = 2;
                        //register user as a delegate 
                    }
                    if (+currentPack[0].no_of_access_badge <= +currentPack[0].registeredCount) {
                        completedPackage.push(currentPack[0].packageId);
                    }
                }

                //move to next package of first user and repeat the same process
            }
            //move to second user and repeat the same process    
        }
        // console.log("packageList.list", packageList.list, completedPackage);
        // if(packageList.list.filter(pack => pack.isSeller==1).length==0 || packageList.list.filter(pack => pack.isBuyer==1).length==0){
        //     setPackShow(true);
        // }
        packList = packageList.list.filter(x => !completedPackage.includes(x.packageId));
        setPackList(packList);

        if (NO_SEATS_FLAG) {
            setMessage(<div style={{ marginTop: '5%', textAlign: 'center' }}><h4 style={{ color: 'red' }}>{"You have reached the maximum number of delegate package for your company for this event."}</h4><p style={{ marginTop: '5%', textAlign: 'center' }}>{` Please contact Alain Godet algodet@channe-hub.io if you need to increase the limit. In the meantime we will create your account on the platform and it will be associated with your company account as selected in the previous steps`}</p></div>);
            setPackFull(true);
        } else if (packageList.pregProcess == 2) {
            setMessage("Select Your Package");
        }
        else if (regProcess == 1) {
            setMessage("Select Your Package");
        }

    }, [])


    useEffect(() => {
        // console.log("packageList:", packageList)
        setPackError(false);
    }, [packageId])



    const selectPackage = (id) => {



        handleChangeSelect('selectedMarketingOptions', []);
        handleChangeSelect('selectedPackageOptions', []);
        handleChangeSelect('packageId', id);

        if (packageId != id) {
            data.selectedMarketingOptions = [];
            data.selectedPackageOptions = [];
        } else {
            data.selectedMarketingOptions = selectedMarketingOptions;
            data.selectedPackageOptions = selectedPackageOptions;
        }

        data.marketingOptions = packages.filter(x => x.packageId == id)?.length ? packages.filter(x => x.packageId == id)[0]?.marketingOptions : [];
        data.packageOptions = packages.filter(x => x.packageId == id)?.length ? packages.filter(x => x.packageId == id)[0]?.packageOptions : [];
        // console.log('data to', data);
        setData({ ...data });
        //}

        // if (channelTypeId == 2)
        //     setIsOpen(true);

        // console.log('isopen', isOpen);
    }

    const closePopUp = () => {
        setData({});
        setIsOpen(false);
    }

    const setParticipant = (type) => {
        //1 - Seller 2 - Buyer        
       if(type==1){
        const packList = packageList.list?.filter(pack => pack.isSeller==1);       
        setEventRegisterType(1);
        setPackList(packList);       
       }else if(type==2){
        const packList = packageList.list?.filter(pack => pack.isBuyer==1);       
        setEventRegisterType(2);
        setPackList(packList);
       }       
        // setPackShow(true);
    }

    

    return (
        <div>
            {isOpen && <EventPackageOptions closeModalPopup={closePopUp} changedData={handleChangeSelect} isOpen={isOpen} data={data} />}
            <div className="main-content" style={{ height: '380px' }}>
                {/* {(packageList.primaryUser != '' && !packFullMsg) &&
                    <p>We already have a participant from your company ({packageList.primaryUser}) registered with a hosted-buyer package. Please select one of the below options if you wish to join your colleague for the event</p>} */}
                <p>{message}</p>

                {/* Seller/Buyer Decision */}

{(packageList.list?.filter(pack => pack.isSeller=='1').length>0 && packageList.list?.filter(pack => pack.isBuyer=='1').length>0) &&
                <div id="pro" class="row prof-row1">
                <div className='col-lg-3'></div>
                <div className='col-lg-6 col-sm-12 selectprof'>
                    <h5 className='mob-signhead' style={{ display: 'none' }}>Register as</h5>

                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle caret>
                            Register as
                        </DropdownToggle>
                        <DropdownMenu>
                            {(props.values?.participantType == 1 || props.values?.participantType === null) && <DropdownItem onClick={() => setParticipant('1')}>
                                <div id="ven" class="img-v"><span id="v" className="prof-span">Seller</span></div>
                            </DropdownItem>}
                            {(props.values?.participantType == 2 || props.values?.participantType === null) && <>
                                <DropdownItem onClick={() => setParticipant('2')}>
                                    <div id="dis" class="img-d"><span id="d" className="prof-span" >Buyer</span></div>
                                </DropdownItem>                                
                            </>}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className='col-lg-3'></div>
                </div>}

                {(packList?.filter(pack => pack.isSeller=='1').length==0 || packList?.filter(pack => pack.isBuyer=='1').length==0) &&
                <PerfectScrollbar>
                    <div className="container" style={{ padding: '0' }}>

                        <div className="row">
                            <div className="col-lg-12 prof-001" style={{ height: "auto" }}>
                                <div class="container" style={{ padding: '0' }}>
                                    <div id="event" class=" prof-row1">
                                        {packList?.filter(p => p.packageType == 1).map((x, i) => {
                                            let offers = packageList.offers?.filter(off => off.offerType == 1 && x.offerIds.split(',').includes(off.couponId.toString()));
                                            return (

                                                <a className='curs-point signupeventpack' key={'curs' + x.packageId}>
                                                    <div onClick={() => selectPackage(x.packageId)} key={x.packageId}>
                                                        <div className={packageId == x.packageId ? 'bg-light pt-2 pb-2 mb-4' : ''}><Row><Col lg='1' >
                                                            <div style={parseInt(x.price) == 990 ? { height: '320px' } : { height: '277px' }} className='rotated center'><h1 className='h4 text-center text-uppercase '>{x.packageName}</h1></div>
                                                        </Col>
                                                            <Col lg='11' >
                                                                {/* <Card className={packageId == x.packageId ? 'm-0' : ''} style={parseInt(x.price) == 0 ? { minWidth: '105%' } : { minWidth: 'auto' } && parseInt(x.price) == 990 ? { minWidth: '117%' } : { minWidth: 'auto' }}> */}
                                                                <Card style={{ width: '95%' }} className={packageId == x.packageId ? 'm-0' : ''} >
                                                                    <CardBody>
                                                                        <div className='row'>
                                                                            {/* <div class="ribbon blue"><span>{x.rib}</span></div> */}
                                                                            <div className="col-md-8">
                                                                                <p class=" text-left text-primary" dangerouslySetInnerHTML={{ __html: x.description }} />
                                                                                {/* <i class="far fa-hand-point-right"></i> {x.description.replace(/<[^>]+>/g, '')}</p> */}
                                                                                {/* <p class="description text-left"> <i class="far fa-hand-point-right"></i> {x.badge}</p>
                                    <p class="description text-left"> <i class="far fa-hand-point-right"></i> {x.agent}</p>
                                    <p class="description text-left"> <i class="far fa-hand-point-right"></i> {x.space}</p> */}
                                                                                {/* {x.meet && <p class="description text-left"> <i class="far fa-hand-point-right"></i> {x.meet}</p>} */}
                                                                                {/* <p class="description text-left"> <i class="far fa-hand-point-right"></i> {x.description}</p> */}
                                                                            </div>
                                                                            <div className="col-md-4"><h1 class="h3 text-info text-center"><span className='mr-2 display-1 font-weight-light'> {parseInt(x.price)}</span><i class="fas fa-euro-sign"></i></h1></div>

                                                                            {offers.length > 0 && offers?.map(o =>

                                                                                <p class=" text-left text-primary"><b>{o.fullDesc}</b></p>
                                                                            )}
                                                                        </div>

                                                                    </CardBody>
                                                                </Card>
                                                            </Col></Row></div>
                                                    </div>
                                                </a>
                                            )
                                        })}
                                        {/* <Row className='d-flex justify-content-center'>
            <Col lg="4" onClick={() => selectPackage(201)} className={packageId==201 ? 'bg-light' : ''}>
            <a href="#">
            <Card className="card-lift--hover border-0 imprt">
            <div class="ribbon blue"><span>Discover</span></div>
                <CardBody className="py-5 card-body">
                        <h1 className='h4 text-info text-center text-uppercase'>Hosted buyer</h1>
                        <h1 class="h3 text-info text-center"><span className='mr-2 display-1 font-weight-light'>8000</span><i class="fas fa-euro-sign"></i></h1>
                        <p class="description text-center">BADGES: <span>1 pass</span></p>
                        <p class="description text-center"><span>Up to 15 pre-scheduled 1-to-1 meetings</span></p>
                        <p id='meet' class="description text-center">MEETING SPACE: <span>Meeting table (1,8m x 0,8m) with 2 chairs</span></p>
                        <UncontrolledTooltip target='meet' placement='bottom'> Meeting table (1,8m x 0,8m) with 2 chairs </UncontrolledTooltip>
                </CardBody >
            </Card>
            </a>
            </Col>

            <Col lg="4" onClick={() => selectPackage(202)} className={packageId==202 ? 'bg-light' : ''}>
            <a href="#">
            <Card className="card-lift--hover border-0 imprt">
            <div class="ribbon blue"><span>Package 1</span></div>
                <CardBody className="py-5 card-body">
                        <h1 className='h4 text-info text-center text-uppercase'>Invited buyer</h1>
                        <h1 class="h3 text-info text-center"><span className='mr-2 display-1 font-weight-light'>13000</span><i class="fas fa-euro-sign"></i></h1>
                        <p class="description text-center">BADGES: <span>2 passes</span></p>
                        <p class="description text-center"><span>Up to 30 pre-scheduled 1-to-1 meetings</span></p>
                        <p id='meet1' class="description text-center ovr-flw">MEETING SPACE: <span>7.5sqm exhibition stand including carpet,stand partition walls, lighting, 1 large meeting table, 4 chairs and 1 display table</span></p>
                        <UncontrolledTooltip target='meet1' placement='bottom'> 7.5sqm exhibition stand including carpet,stand partition walls, lighting, 1 large meeting table, 4 chairs and 1 display table </UncontrolledTooltip>
                </CardBody >
            </Card>
            </a>
            </Col>

            <Col lg="4" onClick={() => selectPackage(203)} className={packageId==203 ? 'bg-light' : ''}>
            <a href="#">
            <Card className="card-lift--hover border-0 imprt">
            <div class="ribbon blue"><span>Package 2</span></div>
                <CardBody className="py-5 card-body">
                        <h1 className='h4 text-info text-center text-uppercase'>Visiting delegate</h1>
                        <h1 class="h3 text-info text-center"><span className='mr-2 display-1 font-weight-light'>18000</span><i class="fas fa-euro-sign"></i></h1>
                        <p class="description text-center">BADGES: <span>3 passes</span></p>
                        <p class="description text-center"><span>Up to 45 pre-scheduled 1-to-1 meetings</span></p>
                        <p id='meet2' class="description text-center ovr-flw">MEETING SPACE: <span>Hospitality Suite in the Hospitality Zone & 7.5sqm exhibition stand OR 15sqm double stand. Furniture provided: 2 large meeting tables, 8 chairs, 2 display table</span></p>
                        <UncontrolledTooltip target='meet2' placement='bottom'>Hospitality Suite in the Hospitality Zone & 7.5sqm exhibition stand OR 15sqm double stand. Furniture provided: 2 large meeting tables, 8 chairs, 2 display table</UncontrolledTooltip>
                </CardBody >
            </Card>
            </a>
            </Col>
            </Row> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {packError && <span style={{ color: 'red' }}>Please Select Package</span>}

                    </div>
                </PerfectScrollbar>}
            </div>

            <button className="btn btn-primary ch-btn-blue right-but" variant="contained" color="primary" onClick={next}>
                Next
            </button>
        </div>
    )
}





import React, { useState, useEffect } from "react";
import {

  Container,
  Row, Modal, ModalFooter,
  ModalHeader, ModalBody,
  Col, CardBody, Card, Nav, Button, NavItem, NavLink, UncontrolledTooltip,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader"
import Title from "views/Hoc/Dialog/Title";
import { ModalSpinner, Spinner } from 'views/Hoc/Spinner';
import { commonService } from '_services/common.service';
import Moment from 'moment';
import ShareIcon from '@mui/icons-material/Share';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import axios from 'axios';
import { history } from "_helpers";
import { useDispatch } from "react-redux";
import { ConfirmDialog } from "views/Hoc/Dialog/GlobalDialog";
import { eventService } from "_services";
import EditIcon from "@mui/icons-material/Edit";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import Box from '@mui/material/Box';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';

var _ = require('lodash');
const EventsNew = (props) => {
  const Linkedin_ClientId = '866eaqbozn09m4'; //process.env.REACT_LINKEDIN_CLIENTID
  // const Linkedin_RedirectUri = 'http://localhost:4200/admin/linkedinconnect';
  const Linkedin_RedirectUri = process.env.REACT_APP_LOCALSITE + 'admin/linkedinconnect';

  const [showMsg, setMsg] = React.useState(false);
  const [companyLogoFlag, setCompanyLogoFlag] = React.useState(false);
  const [contactLogoFlag, setContactLogoFlag] = React.useState(false);
  const [errMsg, setErrorMsg] = React.useState(false);
  const [selectedTab, setTabSelection] = useState(null);
  const [dueAlert, setDueAlert] = useState({ visible: false, item: null, totalSplits: null, vatPercentage: null });
  const [loading, setLoading] = useState(false);
  let [isBoFo, setIsBoFo] = useState(false);
  let [profileData, setProfileData] = useState(null);
  let [list, setList] = useState([]);
  let [upcomeEvent, setUpcomeEvent] = useState([]);
  let [pastEvent, setPastEvent] = useState([]);
  let [listData, setListData] = useState([]);
  let [registeredEvent, setRegisteredEvent] = useState([]);
  let [unRegisteredEvent, setUnRegisteredEvent] = useState([]);

  const dispatch = useDispatch();
  const [tempmodal, settempModal] = React.useState(false);
  const [sharemodal, setshareModal] = useState(false);
  const [shareModalData, setshareModalData] = useState('');
  const [linkedData, setlinkedData] = React.useState({ firstName: '', lastName: '', companyName: '', companyLogo: '', contactLogo: '' });

  // Toggle for Modal 
  const temptoggle = async (shortname, companyName, companyLogo) => {

    setLoading(true);

    var userData = JSON.parse(localStorage.getItem('selectCHUser'));
    if (userData == null) {
      userData = JSON.parse(localStorage.getItem('CHuser'))
    }

    let companyLogos; let contactLogos;

    if (companyLogo)
      companyLogos = await getBase64FromUrl(process.env.REACT_APP_CDN_URL + companyLogo);
    else
      companyLogos = await getBase64FromUrl('https://d35w8j22j5uly8.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg');

    if (userData.documentUrl)
      contactLogos = await getBase64FromUrl(userData.documentUrl);
    else
      contactLogos = await getBase64FromUrl('https://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png');

    let linkedinImagePath = '';
    let sharetitle = '';
    if (shortname === 'MENA24') {
      linkedinImagePath = '/images/Mena_linkedin.jpg';
      sharetitle = "MENA";
    } else if (shortname === 'CSE24') {
      linkedinImagePath = '/images/Emea_linkedin.jpg';
      sharetitle = "EMEA";
    }

    const shareData = { shortname: shortname, linkedinImagePath: linkedinImagePath, firstName: userData.firstName, lastName: userData.lastName, companyName: companyName, companyLogo: companyLogos, contactLogo: contactLogos, sharetitle: sharetitle }

    setlinkedData(shareData);
    settempModal(!tempmodal);
    setLoading(false);
    setshareModal(false);

  }

  useEffect(() => {
    //after event code 
    isBoFo = window.location.hostname === process.env.REACT_APP_BOFO;
    setIsBoFo(isBoFo);
    setLoading(true);
    getEventList();
    //before event code 
    //eventAuth();
  }, []);
  const eventAuth = () => {
    setLoading(true);
    eventRegister();
    if (!localStorage.getItem('eventcatalyst_token')) {
      //   let body = new URLSearchParams();
      //   body.set('grant_type', process.env.REACT_APP_EVENTCAT_GRANT_TYPE);
      //   body.set('username', process.env.REACT_APP_EVENTCAT_USERNAME);
      //   body.set('password', process.env.REACT_APP_EVENTCAT_PASSWORD);
      //   const requestOptions = {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
      //     body: body.toString()
      // };
      // console.log('body',process.env);
      let req = {
        url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
      }
      commonService.postService(req, 'account/eventcatalystToken').then(res => {
        if (res.data?.error) {
          setLoading(false);
          ToastsStore.warning('Something went wrong');
        } else {
          localStorage.setItem('eventcatalyst_token', JSON.stringify(res.data));
          getEventList();
        }
      }).catch(err => {
        setLoading(false);
        console.log('err', err)
      })
    } else {
      getEventList();
    }
  }
  const getEventList = async () => {
    const url = 'getEventRegister';

    //before event

    // let data = {
    //   token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
    //   url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration/' + userData.contactId
    // }

    //end of before event


    let result = await axios.get('/channel/getEventListing').then(res => {
      if (isBoFo) {
        setLoading(false);
        // list = listData = _.orderBy(res.data.registrations, ['date_start'], ['desc']);
        list = _.orderBy(res.data.registrations, ['date_start'], ['desc']);
        listData = _.orderBy(res.data.registrations, ['date_start'], ['desc']);
        //console.log("list:", listData);
        setListData(listData);
        setList(list);
      } else {
        let data = res.data.registrations.filter(x => x.isBofoOnly != '1')
        setLoading(false);
        list = _.orderBy(data, ['date_start'], ['desc']);
        listData = _.orderBy(data, ['date_start'], ['desc']);
        setListData(listData);

        // if(userData.channelTypeId == 2)
        //   list = list.filter(x => x.eventId!=3);

        setList(list);
      }
      // const currEvent = list.filter(item => item.eventStatus == 2);
      // setList(currEvent);

      const pastEvent = list.filter(item => item.eventStatus == 3 && item.channel?.channel?.contacts?.find(con => con.contactId == userData.contactId)?.eventParticipants?.filter(p => p.eventId == item.eventId).length > 0)
      setPastEvent(pastEvent);

      const regEvent = list.filter(item => item.eventStatus == 2 && ((item.channel?.registeredBy == userData.contactId) || (item.channel?.channel?.contacts?.find(con => con.contactId == userData.contactId)?.eventParticipants?.filter(p => p.eventId == item.eventId).length > 0)))
      regEvent.sort(function (a, b) {
        return new Date(a.date_start) - new Date(b.date_start);
      });
      setRegisteredEvent(regEvent);

      const unregEvent = list.filter(item => (item.eventStatus == 2 || item.eventStatus == null) && item.channel?.registeredBy != userData.contactId && !item.channel?.channel?.contacts?.find(con => con.contactId == userData.contactId)?.eventParticipants?.filter(p => p.eventId == item.eventId).length)
      unregEvent.sort(function (a, b) {
        return new Date(a.date_start) - new Date(b.date_start);
      });
      setUnRegisteredEvent(unregEvent);

      // const upcomingEvent = list.filter(item => item.eventStatus == null)
      // setUpcomeEvent(upcomingEvent);

      if (regEvent.length)
        setTabSelection(1);
      else
        setTabSelection(2);

      console.log('list', list)

    });
  }

  var userData = JSON.parse(localStorage.getItem('selectCHUser'));
  if (userData == null) {
    userData = JSON.parse(localStorage.getItem('CHuser'))
  }

  const getCompanyLogo = async (resImagePath) => {

    setCompanyLogoFlag(false);
    if (resImagePath) {
      // console.log("linkeddata:",resImagePath['document'].documentUrl)
      const companyLogo = await getBase64FromUrl(process.env.REACT_APP_CDN_URL + resImagePath['document'].documentPath);
      const shareData = { ...linkedData, companyLogo: companyLogo, companyLogoUrl: process.env.REACT_APP_CDN_URL + resImagePath['document'].documentPath }
      setlinkedData(shareData);
    }
  };

  const getContactLogo = async (resImagePath) => {

    setContactLogoFlag(false);
    if (resImagePath) {
      // console.log("linkeddata:",resImagePath['document'].documentUrl)
      const contactLogo = await getBase64FromUrl(process.env.REACT_APP_CDN_URL + resImagePath['document'].documentPath);
      const shareData = { ...linkedData, contactLogo: contactLogo, contactLogoUrl: process.env.REACT_APP_CDN_URL + resImagePath['document'].documentPath }
      setlinkedData(shareData);
    }

  };

  const downloadCompanyShare = () => {
    setLoading(true);

    // const imageData = getBase64FromUrl(linkedData.companyLogo);

    let child = document.getElementById("compedit");
    child.remove();
    let parent = document.getElementById("linkedimage");

    parent.style.width = "1024px";
    parent.style.height = "1024px";

    let parentcont = document.getElementById("linkcont");
    let parentlogo = document.getElementById("linklogo");
    let parentpara = document.getElementById("linkpara");
    parentcont.style.bottom = "31%";
    parentcont.style.left = "14%";
    parentlogo.style.width = parentlogo.style.height = "200px";
    parentpara.style.fontSize = "30px"
    parentpara.style.bottom = "11%"
    parentpara.style.left = "10%"
    htmlToImage.toBlob(parent)
      .then(function (blob) {
        if (window.saveAs) {
          window.saveAs(blob, linkedData.shortname + '-company-share.png');
        }
      });

    setLoading(false);
    settempModal(false);
  }

  const downloadContactShare = () => {
    setLoading(true);

    // const imageData = getBase64FromUrl(linkedData.companyLogo);

    let child = document.getElementById("conedit");
    child.remove();
    let parent = document.getElementById("linkedimage1");

    parent.style.width = "1024px";
    parent.style.height = "1024px";

    let parentcont = document.getElementById("linkcont1");
    let parentlogo = document.getElementById("linklogo1");
    let parentpara = document.getElementById("linkpara1");
    parentcont.style.bottom = "31%";
    parentcont.style.left = "13%";
    parentlogo.style.width = parentlogo.style.height = "200px";
    parentpara.style.fontSize = "30px"
    parentpara.style.bottom = "11%"
    parentpara.style.left = "10%"
    htmlToImage.toBlob(parent)
      .then(function (blob) {
        if (window.saveAs) {
          window.saveAs(blob, linkedData.shortname + '-contact-share.png');
        }
      });

    setLoading(false);
    settempModal(false);

  }

  const editCompanyLogo = () => {
    setCompanyLogoFlag(true);

  }

  const editContactLogo = () => {

    setContactLogoFlag(true);
  }

  const eventRegister = () => {
    return new Promise((resolve) => {
      commonService.getServices('channel/eventRegister/' + userData.contactId).then(res => {
        profileData = res.data;
        setProfileData(profileData)
        console.log('res.data', res.data);
        resolve(true);
      }, err => {
        resolve(false);
        console.log('err', err);
      });
    })
  }

  const getToken = () => {
    let req = {
      url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
    }
    return commonService.postService(req, 'account/eventcatalystToken').then(res => {
      if (res.data?.error) {
        setLoading(false);
        // ToastsStore.warning('Something went wrong'); 
      } else {
        localStorage.setItem('eventcatalyst_token', JSON.stringify(res.data));
      }
      return JSON.stringify(res.data)
    }).catch(err => {
      setLoading(false);
      console.log('err', err)
    })
  }

  const getManage = async (event) => {
    var userData = JSON.parse(localStorage.getItem('selectCHUser'));
    if (userData == null) {
      userData = JSON.parse(localStorage.getItem('CHuser'))
    }
    // if (event.eventId == 6)
    //   setMsg(true);
    // else {
    setLoading(true);
    if (!localStorage.getItem('eventcatalyst_token')) {
      await getToken();
    }
    await eventRegister();
    let dec = profileData.length > 0 ? profileData[0].detailDesc : null
    if ((dec === null) || (dec === '') || !dec) {
      dec = null;
    } else {
      dec = dec.toString();
      dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
    }
    let data = {
      participantId: parseInt(userData.contactId),
      eventId: event.event_catalyst_id,
      companyId: parseInt(userData.channelId),
      existingCategory: profileData.length > 0 ? profileData[0].ExistingCategory ? profileData[0].ExistingCategory.replace(/,/g, "|") : null : null,
      interestedCategory: profileData.length > 0 ? profileData[0].IntrestedCategory ? profileData[0].IntrestedCategory.replace(/,/g, "|") : null : null,
      profileType: profileData.length > 0 ? profileData[0].ProfileType : null,
      retailCustomerProfile: profileData.length > 0 ? profileData[0].Retail_Customer_Profile ? profileData[0].Retail_Customer_Profile.replace(/,/g, "|") : null : null,
      detailDesc: dec,
      password: profileData.length > 0 ? profileData[0].password : null
    }
    let body = {
      token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
      url: process.env.REACT_APP_EVENTCATALYST_API + 'api/manage',
      body: data
    }
    commonService.postService(body, 'account/postEventRegister').then(res => {
      setLoading(false);
      window.open(res.data.pageUrl, '_blank');
    });
    // }
  }
  const registration = async (event) => {
    // contactInfo = JSON.parse(localStorage.getItem(AppLocalStorageKeys.CONTACT_INFO));
    // let logoUrl= localStorage.getItem('fo-company_logo');
    // if(token == '' || !token){
    //   let checkTokenExists = await getAuthToken(channelId);
    //   if(!checkTokenExists){
    //     ToastsStore.warning('Something went wrong'); 
    //     return 
    //   }
    // }
    if (!isBoFo && userData.channelTypeId === '2') {
      ToastsStore.warning('Something went wrong');
      return
    }
    setLoading(true);
    await eventRegister();
    let dec = profileData.length > 0 ? profileData[0].detailDesc : null
    console.log('dec', dec);
    if ((dec === null) || (dec === '')) {
      dec = null;
    } else {
      dec = dec.toString();
      dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
    }
    let data = {
      "participantId": parseInt(userData.contactId),
      "firstName": profileData[0].firstName,
      "lastName": profileData[0].lastName,
      "email": profileData[0].email,
      "companyName": profileData[0].companyName,
      "companyId": parseInt(userData.channelId),
      "eventId": event.eventId,
      "status": "Confirmed",
      "type": userData.channelTypeId === '2' ? "Vendor" : "Buyer",
      "typeId": parseInt(userData.channelTypeId),
      "timezone": "",
      "matchingScore": [],
      "country": profileData[0].isoCode,
      "profileUrl": process.env.REACT_APP_LOCALSITE + 'channelDetailView/' + userData.channelId,
      "photoUrl": profileData[0].profileimage,
      "mobile": profileData[0].mobileNo,
      "jobTitle": profileData[0].jobTitle,
      "category": userData.channelTypeId === '2' ? 'Vendor' : userData.channelTypeId === '3' ? 'Distributor' : userData.channelTypeId === '4' ? 'Retailer' : 'Reseller',
      "logoUrl": profileData[0].companylogo,
      "existingCategory": profileData.length > 0 ? profileData[0].ExistingCategory ? profileData[0].ExistingCategory.replace(/,/g, "|") : null : null,
      "interestedCategory": profileData.length > 0 ? profileData[0].IntrestedCategory ? profileData[0].IntrestedCategory.replace(/,/g, "|") : null : null,
      "profileType": profileData.length > 0 ? profileData[0].ProfileType : null,
      "retailCustomerProfile": profileData.length > 0 ? profileData[0].Retail_Customer_Profile ? profileData[0].Retail_Customer_Profile.replace(/,/g, "|").replace(/,/g, "|") : null : null,
      "detailDesc": dec,
      "password": profileData.length > 0 ? profileData[0].password : null
    }
    // // Status: Invited, Shortlist, Confirmed, Rejected, Cancelled, WaitingList, Declined
    let body = {
      token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
      url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration',
      body: data
    }
    commonService.postService(body, 'account/postEventRegister').then(res => {
      setLoading(false);
      setEventContact()
      getEventList();
      window.open(res.data.pageUrl, '_blank');
    });
  }
  const setEventContact = () => {
    commonService.getServices('channel/setEventContact/' + userData.contactId).then(res => {

    }, err => {
      console.log('err', err)
    });
  }



  const selectEvent = (item) => {
    //console.log("item:", item)
    let dashboard = userData?.channelTypeId == 2 ? 'vendorDashboard' : 'BuyerDashboard';

    let NO_SEATS_FLAG = true; let regProcess; let completedPackage = [];
    for (let user = 1; user <= 2; user++) {
      for (let pack = 1; pack <= 2; pack++) {
        let currentPack = item.packages.filter(x => x.packageUser == user && x.packageType == pack)
        if (currentPack.length) {
          if (currentPack[0].registeredCount == 0) {
            NO_SEATS_FLAG = false;
            regProcess = 1;
            //move to subscription page with package selection
          }
          else if (currentPack[0].registeredCount > 0 && currentPack[0].registeredCount < currentPack[0].accessBadge) {
            NO_SEATS_FLAG = false;
            regProcess = 2;
            //register user as a delegate 
          }
          if (currentPack[0].accessBadge == currentPack[0].registeredCount) {
            completedPackage.push(currentPack[0].packageId);
          }
        }

        //move to next package of first user and repeat the same process
      }
      //move to second user and repeat the same process    
    }

    dispatch({ type: 'EVENT', data: { ...item, completedPackage: completedPackage } })

    if (item.type == 2 || (item.type == 1 && item.hasPackage == '0'))
      digitalEventRegister(item)
    else {
      if (regProcess == 2)//item.hasPrimaryPackage=='1' && item.alreadyRegistered<item.accessBadge)
        digitalEventRegister(item)
      else if (regProcess == 1)//item.hasSecondaryPackage=='1' || (item.hasPrimaryPackage=='1'&&item.alreadyRegistered==0))
        history.push('/admin/' + dashboard + '/eventSubscription/' + item.eventId);
      else
        history.push('/admin/' + dashboard + '/eventSubscription/' + item.eventId);
      //setErrorMsg(true);

    }
  }

  const digitalEventRegister = (item) => {

    let userData = JSON.parse(localStorage.getItem('selectCHUser'));
    if (userData == null) {
      userData = JSON.parse(localStorage.getItem('CHuser'))
    }
    let eventDetails = {
      eventId: item.eventId,
      packageId: item.packageId,
      regTemplate: item.regTemplate,
    };

    dispatch({ type: 'EVENT_USERS', data: { contacts: [userData.contactId] } });
    dispatch({ type: 'EVENT_PACK', data: { ...eventDetails } });


    let payment = {
      paymentStatus: '0',
      paymentMethod: null,
      paymentTransactionId: null,
      isFromFO: 1,
      eventType: item.type,
      isDelegate: true
    };

    eventService.eventRegistration(payment);

  }

  const handleTabSelection = (id) => {
    setTabSelection(id);
  }


  const displayMsg = (value) => {
    setMsg(false);
    setErrorMsg(false);
  }

  const closeMsg = () => {
    setMsg(false);
    setErrorMsg(false);
  }
  const sharetoggle = (item) => {
    localStorage.setItem("Linked_Share_EventId", item.eventId);
    setshareModal(!sharemodal);
    setshareModalData(item);
  }

  const duesAlert = (item, totalSplits, vatPercentage) => {






    const setManage = () => {

      setDueAlert({ ...dueAlert, visible: false });
      let pack = item.channel?.package;

      let packDetails = {};
      if (item.type == 1 && pack) {
        packDetails = {
          eventId: item.eventId,
          packageId: pack.packageId,
          price: pack.price,
          additionalMembersCosting: pack.additionalMembersCosting,
          membersAllowed: pack.no_of_access_badge,
          packageName: pack.packageName,
          isSeller: pack.isSeller,
          isBuyer: pack.isBuyer,
          additionalMembers: item.channel?.additionalMembers ? item.channel?.additionalMembers : 0,
          marketingOptions: pack.marketingOptions,
          packageOptions: pack.packageOptions
        };
      }


      dispatch({ type: 'EVENT_PACK', data: { ...packDetails } });
      dispatch({ type: 'EVENT', data: { ...item } });
      if (item.type == 2)
        history.push('events/buyersession/' + item.eventId);
      else if (item.type == 1)// && !pack))
        history.push('/admin/events/eventManagement/' + item.eventId);
      else
        history.push('/admin/events/eventManage');

    }





    return (
      <div>
        <Modal className="modal-dialog-centered paymentpopup" fade={false} isOpen={dueAlert} size="xg" style={{ minWidth: 900, width: '30%' }}>
          <div className="modal-header">
            <strong>{'Summary of your payment status'}</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setDueAlert({ ...dueAlert, visible: false })}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">

            {/* <Box
                      sx={{
                          height: 600,
                          width: '100%',
                          '& .actions': {
                              color: 'text.secondary',
                          },
                          '& .textPrimary': {
                              color: 'text.primary',
                          },
                      }}
                  > */}

            <div>

              {totalSplits?.map(split => {

                let name = "";
                //console.log("cons:", item, totalSplits, item.channel?.channel?.contacts, split.contactId)
                let packageName = item.packages.find(pack => pack.packageId == split.packageId).packageName;

                let contact = item.channel?.channel?.contacts.find(con => con.contactId == split.contactId);
                if (contact) {
                  name = contact.firstName + " " + contact.lastName;

                } else {
                  name = item.regUserDetail['firstName'] + " " + item.regUserDetail['lastName'];
                }
                let actualPrice = split.splitAmount;
                /*if (vatPercentage > 0) {
                  actualPrice = (parseFloat(split.splitAmount) + (parseFloat(split.splitAmount) * (vatPercentage / 100))).toFixed(2);
                }*/


                return <>
                  <Col lg='12' className='event-card p-3 mb-4'>

                    <Row>
                      <p>{`Reminder: you colleague ${name} has ordered the following service ${packageName}, make sure that the payment is processed at the earliest to avoid any inconvenience in the event registration and attendance`}</p>
                    </Row>
                    <Row>
                      <Col lg='6' className='pt-3'>

                        <div className='text-left'>
                          {split.paymentDate &&
                            <h4>{"Payment Date: " + Moment(split.paymentDate).format('DD-MM-YYYY')}</h4>}
                          <h4>{"Due Amount: " + actualPrice.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')}{split?.currCode == 'USD' ? ' $' : ' €'}</h4>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </>
              })}

            </div>


            {/* </Box> */}

          </div>
          <div className="modal-footer">
            <Button color="primary" type="button" onClick={() => setManage()}>Ok</Button>
            {/* <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button> */}
          </div>
        </Modal>
      </div>
    )
  }

  const setManage = async (item, totalSplits) => {




    if (totalSplits?.filter(x => x.paymentStatus == 0).length) { // && x.paymentMethod == 2).length) {

      let pack = item.packages.find(p => p.packageId == totalSplits[0].packageId);//channel?.package;

      let offers = (pack.offerIds && pack.offerIds != '') ? pack.offerIds.split(',') : [];

      let vatPercentage = 0;

      await commonService.postService({ eventId: item.eventId, offers: offers, channelTypeId: userData.channelTypeId, regType: userData.eventRegisterAs == 1 ? 'Seller' : 'Buyer', channelId: userData.channelId, contactId: userData.contactId }, '/channelAdmin/packageOffers').then(res => {
        //packOffers = res.data.offers;
        vatPercentage = res.data.hasVat;
        // vatNumber = res.data.vatNumber;
        // isVatCompany = res.data.isVatCompany;
        // offerAmount = res.data.offers.filter(x => x.offerType == 1).length ? res.data.offers.filter(x => x.offerType == 1).reduce((a, curr) => parseInt(a) + parseInt(curr.couponOfferValue), 0) : 0;
      })

      setDueAlert({ ...dueAlert, vatPercentage: vatPercentage, visible: true, item: item, totalSplits: totalSplits.filter(x => x.paymentStatus == 0) });

    }
    else {

      let pack = item.channel?.package;

      // console.log('packs:', pack)

      //code changed @16/8
      let packDetails = {};
      if (item.type == 1 && pack) {
        packDetails = {
          eventId: item.eventId,
          packageId: pack.packageId,
          price: pack.price,
          additionalMembersCosting: pack.additionalMembersCosting,
          membersAllowed: pack.no_of_access_badge,
          packageName: pack.packageName,
          isSeller: pack.isSeller,
          isBuyer: pack.isBuyer,
          additionalMembers: item.channel?.additionalMembers ? item.channel?.additionalMembers : 0,
          marketingOptions: pack.marketingOptions,
          packageOptions: pack.packageOptions
        };
      }

      //For MENA Event redirect to catalyst only for buyer participants
      // if (pack.isBuyer == '1' && item.openCatalyst == 1) {
      if (!isBoFo && item.openCatalyst == 1) {
        getManage(item);
      } else {
        // if (item.eventId == 6)
        //   setMsg(true);
        // else {
        dispatch({ type: 'EVENT_PACK', data: { ...packDetails } });
        dispatch({ type: 'EVENT', data: { ...item } });
        if (item.type == 2)
          history.push('events/buyersession/' + item.eventId);
        else if (item.type == 1)// && !pack))
          history.push('/admin/events/eventManagement/' + item.eventId);
        else
          history.push('/admin/events/eventManage');

      }



    }

  }
  // }

  const payment = async (item, currentSplit, totalSplit) => {

    var userData = JSON.parse(localStorage.getItem('selectCHUser'));
    if (userData == null) {
      userData = JSON.parse(localStorage.getItem('CHuser'))
    }
    let pack = item.packages.find(p => p.packageId == currentSplit.packageId);//channel?.package;
    let packOffers = []; let vatPercentage; let vatAmount; let offerAmount = 0; let vatNumber; let isVatCompany;
    // console.log("pcka:", item);



    //if (pack.offerIds && pack.offerIds != '') {
    let offers = (pack.offerIds && pack.offerIds != '') ? pack.offerIds.split(',') : [];
    await commonService.postService({ eventId: item.eventId, offers: offers, channelTypeId: userData.channelTypeId, regType: userData.eventRegisterAs == 1 ? 'Seller' : 'Buyer', channelId: userData.channelId, contactId: userData.contactId }, '/channelAdmin/packageOffers').then(res => {
      packOffers = res.data.offers;
      vatPercentage = res.data.hasVat;
      vatNumber = res.data.vatNumber;
      isVatCompany = res.data.isVatCompany;
      offerAmount = res.data.offers.filter(x => x.offerType == 1).length ? res.data.offers.filter(x => x.offerType == 1).reduce((a, curr) => parseInt(a) + parseInt(curr.couponOfferValue), 0) : 0;
    })
    // }

    // console.log("offerAmount:", packOffers, offerAmount, vatPercentage);
    const paymentDetails = {
      actualAmount: pack.price,
      package: [{ pacakgeId: pack.packageId, price: pack.price, packageName: pack.packageName }],
      channelId: userData.channelId,
      contactId: userData.contactId,
      // finalAmount: pack.price - offerAmount,
      finalAmount: currentSplit.splitAmount,//.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.',','),
      eventId: item.eventId,
      offerAmount: offerAmount,
      offers: packOffers,
      packageId: pack.packageId,
      vatPercentage: vatPercentage,
      isVatCompany: isVatCompany,
      vatNumber: vatNumber ? vatNumber : '',//vatPercentage?vatNumber:'',
      isPrimary: true,
      isSeller: item.channel.package['isSeller'],
      isBuyer: item.channel.package['isBuyer'],
      splitId: currentSplit.splitId,
      pennyInvoiceId: currentSplit.pennyInvoiceId,
      paymentMethod: currentSplit.paymentMethod,
      currCode: currentSplit.currCode,
      totalSplit: totalSplit,
      discount: currentSplit.discount
    }

    localStorage.setItem('eventRedirect', JSON.stringify({ paymentDetails: paymentDetails }));
    dispatch({ type: 'EVENT_PAYMENT', data: paymentDetails });

    dispatch({ type: 'EVENT', data: { ...item } });

    history.push('/admin/Subscription/EventPayment');

  }

  const viewManage = (item) => {

    // dispatch({ type: 'EVENT_PACK', data: { ...packDetails } });

    localStorage.setItem('eventDetails', JSON.stringify(item));
    dispatch({ type: 'EVENT', data: { ...item } });
    history.push('events/prevEvent');
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const selectImg = async (data) => {
    if (data.length > 0) {
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + ".png";
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append("file", file);
      let aws_folder = "shareinlinkedin";
      const resImagePath = await commonService.fileUploadService(
        formData,
        "/mediaupload/upload/" + aws_folder
      );
      if (resImagePath) {
        console.log("Linked_shareUrl:", resImagePath);
        localStorage.setItem("Linked_shareUrl", resImagePath.data.url);
        setLoading(false);
      } else {
        loading = false;
        setLoading(loading);
      }
    }
    window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${Linkedin_ClientId}&redirect_uri=${Linkedin_RedirectUri}&state=random_string&scope=openid profile w_member_social email`, '_self', 'rel=noopener noreferrer');
  };

  const getBase64FromUrl = async (url) => {
    // console.log('url:',url)  

    let body = {
      //token: token, //JSON.parse(localStorage.getItem('eventcatalyst_token')),
      url: url
      //body: fav
    }

    const imageData = await axios.post('channel/getimageforlinkedin', body);


    return imageData.data;


  }

  const linkedin_share = async () => {
    setLoading(true);

    // const imageData = getBase64FromUrl(linkedData.companyLogo);

    let child = document.getElementById("compedit");
    child.remove();

    const offScreenContainer = document.createElement('div');
    offScreenContainer.style.position = 'absolute';
    offScreenContainer.style.left = '-9999px';
    document.body.appendChild(offScreenContainer);
    let parent = document.getElementById("linkedimage");

    const clonedParent = parent.cloneNode(true);
    offScreenContainer.appendChild(clonedParent);

    clonedParent.style.width = '1200px';
    clonedParent.style.height = '1200px';
    const clonedParentCont = clonedParent.querySelector('#linkcont');
    const clonedParentLogo = clonedParent.querySelector('#linklogo');
    const clonedParentPara = clonedParent.querySelector('#linkpara');

    clonedParentCont.style.bottom = '25.5%';
    clonedParentCont.style.left = '10.5%';
    clonedParentCont.style.width = clonedParentCont.style.height = '300px';

    clonedParentLogo.style.width = clonedParentLogo.style.height = '300px';
    clonedParentLogo.style.display = 'flex';
    clonedParentLogo.style.alignItems = 'center';
    clonedParentLogo.style.justifyContent = 'center';

    clonedParentPara.style.fontSize = '30px';
    clonedParentPara.style.bottom = '11%';
    clonedParentPara.style.left = '10%';

    htmlToImage.toBlob(clonedParent)
      .then(function (blob) {
        document.body.removeChild(offScreenContainer);
        let file = blob;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          // console.log("file", file);
          // console.log("reader.result", reader.result);        
          selectImg(reader.result);

        };
      });


  };

  const linkedin_share1 = () => {
    setLoading(true);

    let child = document.getElementById("conedit");
    child.remove();

    const offScreenContainer = document.createElement('div');
    offScreenContainer.style.position = 'absolute';
    offScreenContainer.style.left = '-9999px';
    document.body.appendChild(offScreenContainer);
    let parent = document.getElementById("linkedimage1");

    const clonedParent = parent.cloneNode(true);
    offScreenContainer.appendChild(clonedParent);

    clonedParent.style.width = '1200px';
    clonedParent.style.height = '1200px';
    const clonedParentCont = clonedParent.querySelector('#linkcont1');
    const clonedParentLogo = clonedParent.querySelector('#linklogo1');
    const clonedParentPara = clonedParent.querySelector('#linkpara1');

    clonedParentCont.style.bottom = '25.5%';
    clonedParentCont.style.left = '10.5%';
    clonedParentCont.style.width = clonedParentCont.style.height = '300px';

    clonedParentLogo.style.width = clonedParentLogo.style.height = '300px';
    clonedParentLogo.style.display = 'flex';
    clonedParentLogo.style.alignItems = 'center';
    clonedParentLogo.style.justifyContent = 'center';

    clonedParentPara.style.fontSize = '30px';
    clonedParentPara.style.bottom = '11%';
    clonedParentPara.style.left = '10%';

    htmlToImage.toBlob(clonedParent)
      .then(function (blob) {
        document.body.removeChild(offScreenContainer);
        let file = blob;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          // console.log("file", file);
          // console.log("reader.result", reader.result);        
          selectImg(reader.result);

        };
      });


  };


  const linkedin_share3 = () => {
    setLoading(true);

    let child = document.getElementById("conedit");
    child.remove();
    let parent = document.getElementById("linkedimage3");

    parent.style.width = "1024px";
    parent.style.height = "1024px";
    let parentcont = document.getElementById("linkcont3");
    let parentlogo = document.getElementById("linklogo3");
    let parentpara = document.getElementById("linkpara3");
    parentcont.style.bottom = "31%";
    parentcont.style.left = "13%";
    parentlogo.style.width = parentlogo.style.height = "200px";
    parentpara.style.fontSize = "30px"
    parentpara.style.bottom = "11%"
    parentpara.style.left = "10%"


    htmlToImage.toBlob(parent)
      .then(function (blob) {
        let file = blob;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          // console.log("file", file);
          // console.log("reader.result", reader.result);        
          selectImg(reader.result);

        };
      });


  };


  return (
    <>
      <SimpleHeader location={props.match} />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <div id='Events' className="main-content">

        {dueAlert.visible && duesAlert(dueAlert.item, dueAlert.totalSplits, dueAlert.vatPercentage)}

        {showMsg &&
          <ConfirmDialog open={showMsg} value={{ message: 'The event module will open shortly, check your emails to see the notification message', title: 'Channel Summit', value: null, popUpId: null, second_message: null }} handleSave={displayMsg} handleClose={closeMsg} />}

        {errMsg &&
          <ConfirmDialog open={errMsg} value={{ message: "Maximum limit of participants reached for the selected package. Please contact your company's registered participants for the event", title: 'Retail Connect', value: null, popUpId: null, second_message: null }} handleSave={displayMsg} handleClose={closeMsg} />}

        <Title title="Events" desc={["List of events and tradeshows happening in channelhub"]} />
        <Container fluid className="mt-4">

          {loading ? <Spinner /> :
            <Card>
              <CardBody className="pb-0 eventsnewTabs">
                <div className="tabs" style={{ display: 'flex', flexDirection: 'row' }}>

                  <div id="currentEvent" onClick={() => handleTabSelection(1)}>
                    <label for="currentEvent" className="tabs-label" style={selectedTab == 1 ? { borderBottom: "2px solid #32a1ce" } : {}} >Registered Upcoming Event</label>
                  </div>

                  <div id="upcomeEvent" onClick={() => handleTabSelection(2)} style={{ marginLeft: "1.2rem" }}>
                    <label for="upcomeEvent" className="tabs-label" style={selectedTab == 2 ? { borderBottom: "2px solid #32a1ce" } : {}}>Upcoming Events</label>
                  </div>

                  <div id="pastEvent" onClick={() => handleTabSelection(3)} style={{ marginLeft: "1.2rem" }}>
                    <label for="pastEvent" className="tabs-label" style={selectedTab == 3 ? { borderBottom: "2px solid #32a1ce" } : {}}>Your Past Events</label>
                  </div>
                </div>

                {selectedTab == 1 &&
                  <div className="tabs-content">
                    {registeredEvent.length > 0 &&
                      registeredEvent.map(item => {


                        let companyName = item.channel?.channel?.companyName;

                        let companyLogo = item.channel?.channel?.['channelDetail']?.channelLogo['documentPath'];


                        let unpaidPaymentList = item.eventPayment.filter(x => x.eventId == item.eventId && x.contactId == userData.contactId && x.paymentStatus == '0');

                        let unpaidCount = item.eventPayment.filter(x => x.eventId == item.eventId && x.contactId == userData.contactId && x.paymentStatus == '0').length;

                        let paidCount = item.eventPayment.filter(x => x.eventId == item.eventId && x.contactId == userData.contactId && x.paymentStatus == '1').length;

                        let wireCount = item.eventPayment.filter(x => x.eventId == item.eventId && x.contactId == userData.contactId && x.paymentStatus == '0' && x.wireTransfer == '1').length;

                        let allPaymentList = item.eventPayment.filter(x => x.eventId == item.eventId && x.channelId == userData.channelId);

                        let totalSplits = allPaymentList.sort((a, b) => a.splitId - b.splitId);
                        let eventPayment = unpaidPaymentList.sort((a, b) => a.splitId - b.splitId)[0];

                        console.log("eventPayment:", unpaidCount, paidCount, wireCount)

                        let participant = item.channel?.channel?.contacts?.find(con => con.contactId == userData.contactId)?.eventParticipants.find(p => p.eventId == item.eventId);
                        let hideButton = false;

                        if (!hideButton && (userData.channelTypeId != 2 && item.buyerSecondaryToRegister == '0' && userData.roleId != 1) ||
                          (userData.channelTypeId == 2 && ((item.vendorSecondaryToRegister == '0' && userData.roleId != 1) || (item.type == 1 && item.channel?.registeredBy == userData.contactId))))
                          hideButton = true;

                        return (

                          <Row className="justify-content-center">
                            <Col className="card-wrapper ct-example" lg="12" md="12" sm="12" >
                              <Card className="Bgbg">
                                <CardBody>
                                  {/* <Row className="align-items-center"> */}
                                  <Row>
                                    <Col className="col-xl-2 col-lg-2 col-md-12 col-sm-12 mobi-res text-center">
                                      <a
                                        className="avatar avatar-xl mb-2 details"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} alt="..." src={item.logo ? process.env.REACT_APP_CDN_URL + item?.logo : "https://static.wixstatic.com/media/76c928_c8c2edb7ca4347cfb5f6a94cc986bdda~mv2.png/v1/fill/w_188,h_118,al_c,q_85,usm_0.33_1.00_0.00/RETAIL_CONNECT_ONE_TO_ONE_EMEA.webp"} />
                                      </a>

                                      {/* Waiting for opening in event catalyst */}

                                      {/* <div class="event-meet col-xs-12">
                                  {item.vendorStatus == 4 && !!item.channel && !!item.channel?.channel?.contacts?.length && item.channel?.approvalStatus == 1 && item.channel?.channel?.contacts[0]?.eventParticipants[0]?.status == '1' && item.channel?.channel?.contacts[0]?.eventParticipants[0]?.isEventCatalyst == '1' &&
                                    <button className='mt-4 mb-3 btn btn-primary' size="sm" onClick={() => getManage(item)}>Meeting Scheduler</button>
                                  }
                                </div> */}

                                      {/* <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"  onClick={() => getManage(item)}>Meeting Scheduler</button> */}
                                    </Col>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mobi-res3 text_elips" style={{ "maxHeight": "auto", "white-space": "initial" }}>
                                      <h3 className="mb-0 text-uppercase ">
                                        {item.event_name}
                                      </h3>
                                      <p>
                                        {item.description.replace(/<[^>]+>/g, '')}
                                      </p>
                                    </div>
                                    <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12 col-xs-12 mobi-res5 pr-0">
                                      <h3>
                                        Date and Venue
                                      </h3>

                                      <p className="mb-0">
                                        <i class="fa fa-calendar mr-2" aria-hidden="true"></i>{Moment(item.date_start).format('D MMMM y')} - {Moment(item.date_end).format('D MMMM y')}</p>
                                      <p className="mb-0">


                                        <i class="fa fa-map-marker mr-2" aria-hidden="true"></i>{(item.location && item.location.country) ? item.location.country : ""}


                                      </p>



                                    </div>
                                    <div className="col-xl-2 col-sm-12 col-xs-12 mobi-res5 text-center">
                                      <div className="d-inline">
                                        {/* <p>
                   {Moment(item.date_start).format('M')}    </p>       */}
                                        {(Moment(item.date_start).format('yyyy') >= 2022 && Moment(item.date_start).format('M') >= 1) ?
                                          <>
                                            {item.type == 1 && item.primaryPending === '1' && item.eventStatus != 3 && !participant && (userData.channelTypeId == 2 && item.channel?.registeredBy != userData.contactId && item.type == 1) &&
                                              <>
                                                <Button id='pendingtip' className="btn text-center mt-0" variant="contained" color="grey" style={{ backgroundColor: "gray", color: "white" }}>Register</Button>
                                                <UncontrolledTooltip target={'pendingtip'} placement="bottom">You cannot apply yet for the event as one of your colleague is currently pending for approval or payment, please check later</UncontrolledTooltip>
                                              </>
                                            }
                                            {/* before event code  */}
                                            {!hideButton && item.primaryPending !== '1' && ((userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && !item.statusDate && item.statusDate === '' && item.channel?.registeredBy != userData.contactId && (!item.channel || !participant || (item.channel?.approvalStatus == '1' && !item.channel?.channel?.contacts?.length)
                                              || (item.type == 2 && !item.channel?.channel?.contacts?.length)) && <Button className="btn btn-primary ch-btn-blue text-center mt-0 meet" variant="contained" color="primary" type="button"
                                                //disable register button temp
                                                onClick={() => {

                                                  if ((item.vendorStatus == 4 && userData.channelTypeId == 2) || (item.buyerStatus == 4 && userData.channelTypeId != 2)) {
                                                    //   if (userData.channelTypeId != 2)
                                                    //     setManage(item)
                                                    //   else
                                                    //     window.open('https://www.channel-summit.com/shop', '_blank');
                                                    if (item.vendorStatus == 4 && userData.channelTypeId == 2)
                                                      window.open(item.sellerEventRegisterURL, '_blank');
                                                    else if (item.buyerStatus == 4 && userData.channelTypeId != 2)
                                                      window.open(item.buyerEventRegisterURL, '_blank');
                                                  } else //{
                                                    //   if (userData.channelTypeId != 2)
                                                    selectEvent(item);
                                                  //   else
                                                  //     item.eventId == 5 ? window.open('https://www.channelconnect1to1.com/select-your-package', '_blank') : window.open('https://www.retailconnect1to1.com/product-page/GRC-ifa-22', '_blank');
                                                  // }
                                                  //registration(item);

                                                }
                                                }>Register</Button>}

                                            {/* end of before event code */}

                                            {/* {item.statusDate && item.statusDate!=='' && <Button className='mt-4 mb-3 manag' size="md" type="button" onClick={()=>getManage(item)} >Manage</Button>} */}
                                            {/* Manage Inside CH events */}

                                            {/* {!!item.channel && !!item.channel?.channel?.contacts?.length && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary" 
                                onClick={() => (item.channel?.approvalStatus == 1 && item.channel?.channel?.contacts[0]?.eventParticipants[0]?.status == '1') ? setManage(item) : ''} >
                                  {item.channel?.approvalStatus == '1' && item.channel?.channel?.contacts[0]?.eventParticipants[0]?.status == '1' ? 'Manage' : 'Pending'}</button>}
                              </> : <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary">Completed</button>}
                              */}

                                            {/* Manage Inside CH events */}
                                            {/* prev code (16/8): !!item.channel && item.channel?.channel?.contacts[0]?.eventParticipants[0]?.channelType != 2  */}

                                            {/* <span style={{cursor:"pointer"}} onClick={() => temptoggle(companyName)}><ShareIcon /></span> </>} */}


                                            {(paidCount > 0 || unpaidCount == 0) && ((userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && (!!item.channel?.channel?.contacts?.length || (item.channel?.registeredBy == userData.contactId && item.type == 1)) && (item.channel?.paymentstatus == '1' || item.channel?.paymentstatus == null || item.channel?.package.price == 0) && item.channel?.approvalStatus == '1' && (participant?.status == '1' || (item.channel?.registeredBy == userData.contactId && item.type == 1)) && (participant?.eventId == item.eventId || (item.channel?.registeredBy == userData.contactId && item.type == 1)) && <> <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"
                                              // onClick={() => (item.channel?.approvalStatus == 1 && participant?.status == '1') ? setManage(item) : ''} >
                                              // onClick={() => (participant?.isEventCatalyst == '1')?getManage(item):setManage(item)} >
                                              onClick={() => setManage(item, totalSplits)} style={{ padding: '0.5rem', fontSize: '0.750rem' }}>
                                              {/* {item.channel?.paymentstatus == '1' && item.channel?.approvalStatus == '1' && participant?.status == '1' ? 'Manage' : 'Pending'}</button> : */}
                                              Manage</button>
                                              {(participant?.shareStatus === '0' || participant?.shareStatus == null) &&
                                                <span className="shareicon listing-share" style={{ cursor: "pointer", padding: '0.53rem', fontSize: '0.750rem', top: '0' }} onClick={() => sharetoggle(item)}><ShareIcon /> Share</span>} </>}

                                            <Modal className="sharemodal" isOpen={sharemodal} toggle={() => setshareModal(!sharemodal)}>
                                              <ModalBody>
                                                <div className='row'>
                                                  <div className='col-lg-6'>
                                                    <div className='sharemodal-left'>
                                                      <h2>Share Your Participation!</h2>
                                                      <p>Share with your network that you're attending our event!</p>
                                                      <div style={{ marginTop: '30px' }}><Button className="btn"
                                                        onClick={() => temptoggle(shareModalData.shortname, companyName, companyLogo)}

                                                      >
                                                        <ShareIcon /> Share</Button>
                                                        <img className='linkedin' src={" /images/in.png "} onClick={() => linkedin_share3()} />
                                                      </div>
                                                      <div><img className='channel-logo' src={"/images/channesummit.png"} /></div>
                                                      <div>
                                                        {/* <Button className="share-linkedin" onClick={() => linkedin_share()}><ShareIcon /> Share in Linkedin </Button>
                                                        <Button className="share-linkedin down-btn samp-down-btn" onClick={() => downloadCompanyShare()}>Download</Button> */}

                                                      </div>
                                                      <div style={{ cursor: "pointer" }} className='res-close'><span className='sharetemp-close' onClick={() => setshareModal(!sharemodal)}>X</span></div>

                                                    </div>
                                                  </div>
                                                  <div className='col-lg-6' style={{ paddingRight: '0' }}>
                                                    <div className="tempeft-image" id="linkedimage3">
                                                      <img src={"/images/sharetemp.png"} />
                                                      {/* <div className="temp-cont" id="linkcont3">
                                                        <div className="temp-logo" id="linklogo3">

                                                          <img src={'/images/francepresident.jpg'} />
                                                        </div>
                                                      </div> */}
                                                      {/* <p className="temp-para" id="linkpara1" style={{ fontWeight: '600 !important' }}><span>Emmanuel Macron</span><br /> <span>France President</span></p> */}
                                                      <div style={{ cursor: "pointer" }} className='des-close'><span className='sharetemp-close' onClick={() => setshareModal(!sharemodal)}>X</span></div>

                                                    </div>

                                                  </div>
                                                </div>
                                              </ModalBody>
                                            </Modal>



                                            {/* before partial payment */}
                                            {/* {(((item.viaEventCatalyst == 0 || item.viaEventCatalyst == null) && userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && (!!item.channel?.channel?.contacts?.length || (userData.channelTypeId == 2 && item.channel?.registeredBy == userData.contactId && item.type == 1)) && (item.channel?.paymentstatus == '0' || item.channel?.paymentstatus == null) && item.channel?.wireTransfer==null && item.channel?.approvalStatus == '1' && (participant?.status == '1' || (userData.channelTypeId == 2 && item.channel?.registeredBy == userData.contactId && item.type == 1)) && (participant?.eventId == item.eventId || item.channel?.eventId == item.eventId) && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"                                              
                                              onClick={() => payment(item)} >                                              
                                              Payment Pending</button>} */}

                                            {unpaidCount > 0 && paidCount == 0 && wireCount == 0 && (((item.viaEventCatalyst == 0 || item.viaEventCatalyst == null) && userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && (!!item.channel?.channel?.contacts?.length || (userData.channelTypeId == 2 && item.channel?.registeredBy == userData.contactId && item.type == 1)) && (eventPayment?.paymentStatus == '0' || eventPayment?.paymentStatus == null) && item.channel?.approvalStatus == '1' && (participant?.status == '1' || (userData.channelTypeId == 2 && item.channel?.registeredBy == userData.contactId && item.type == 1)) && (participant?.eventId == item.eventId || item.channel?.eventId == item.eventId) && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"
                                              onClick={() => payment(item, eventPayment, totalSplits)} >
                                              {eventPayment?.paymentMethod == 1 ? "Payment Pending" : "Partial Payment"}</button>}

                                            {/* && (item.channel?.paymentstatus == '0' && item.channel?.wireTransfer=='1' && item.channel?.paymentMethod==2) */}
                                            {wireCount > 0 && paidCount == 0 && (((item.viaEventCatalyst == 0 || item.viaEventCatalyst == null) && userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && (!!item.channel?.channel?.contacts?.length || (userData.channelTypeId == 2 && item.channel?.registeredBy == userData.contactId && item.type == 1)) && item.channel?.approvalStatus == '1' && (participant?.status == '1' || (userData.channelTypeId == 2 && item.channel?.registeredBy == userData.contactId && item.type == 1)) && (participant?.eventId == item.eventId || item.channel?.eventId == item.eventId) && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"
                                            // onClick={() => (item.channel?.approvalStatus == 1 && participant?.status == '1') ? setManage(item) : ''} >
                                            // onClick={() => payment(item)}
                                            >
                                              {/* {item.channel?.paymentstatus == '1' && item.channel?.approvalStatus == '1' && participant?.status == '1' ? 'Manage' : 'Pending'}</button> : */}
                                              Payment Approval</button>}

                                            {(item.buyerStatus == 2 || item.vendorStatus == 2) && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary">Sold Out</button>}

                                            {((userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && !!item.channel?.channel?.contacts?.length && participant?.isEventCatalyst != '1' && participant?.eventId == item.eventId && participant?.status != '1' && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"
                                            >Pending</button>}

                                            {/* {((userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && !!item.channel?.channel?.contacts?.length && participant?.isEventCatalyst != '1' && item.channel?.paymentstatus == '0' && item.channel?.package['price']!=0 && item.channel?.approvalStatus == '1' && participant?.status == '1' && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"
                                          onClick={() => payment(item)}>Payment Pending</button>} */}
                                            {/* Waiting for opening in event catalyst */}

                                            {/* prev code (16/8):  */}
                                            {/* {item.eventStatus != 3 && !!item.channel && !!item.channel?.channel?.contacts?.length && item.channel?.approvalStatus == 1 && participant?.status == '1' && participant?.isEventCatalyst == '1' &&
                                          <button className="btn btn-primary ch-btn-blue text-center mt-0 meet" variant="contained" color="primary" onClick={() => getManage(item)}>
                                            {participant?.isEventCatalyst == '1' ? 'Manage' : 'Pending'}
                                          </button> //Meeting Scheduler  replaced by manage
                                        } */}

                                            {/* prev code (16/8): userData.channelTypeId != 2 && */}
                                          </> : <Button className='mt-4 mb-3 btn btn-success' size="sm" type="button">Completed</Button>}



                                        {/* {item.channel?.channel?.contacts?.length == 0 && item.channel?.approvalStatus == null && <>
                                    <Button id={'view' + item.eventId} className='mt-4 mb-3 btn btn-success' size="sm" type="button">Waiting</Button>
                                    <UncontrolledTooltip target={'view' + item.eventId} placement='bottom'>
                                      Wait for Main Delegate Approval
                                    </UncontrolledTooltip>  */}

                                        {/* after event code */}
                                        {/* prev code (16/5): && item.channel?.channel?.contacts[0]?.eventParticipants[0]?.isEventCatalyst == '1'  */}
                                        {item.eventStatus === 3 ? item.channel?.channel?.contacts?.find(con => con.contactId == userData.contactId)?.eventParticipants?.filter(p => p.eventId == item.eventId).length > 0 ? // && participant?.isEventCatalyst == '1' ?
                                          <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary" onClick={() => viewManage(item)}>View</button> :
                                          ((item.eventStatus != 1 && item.eventStatus != 2) && <button className="btn btn-primary ch-btn-blue text-center mt-0 meet" variant="contained" color="primary" >
                                            Completed
                                          </button>)
                                          :
                                          <></>
                                        }

                                      </div>

                                    </div>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        )
                      })}
                    {(registeredEvent.length <= 0 && !loading) && <div className="no-records ">
                      {/* <span>No Records</span> */}
                      <div className="no-img text-center" style={{ width: '32%' }}>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} /></div>

                      {
                        'Unfortunately, there is no matching events at the moment. '
                      }
                    </div>
                    }
                  </div>
                }

                {selectedTab == 2 &&
                  <div className="tabs-content">

                    {unRegisteredEvent.length > 0 &&
                      unRegisteredEvent.map(item => {


                        let paymentPending = item.eventPayment.length ? item.eventPayment?.filter(x => x.eventId == item.eventId && x.contactId == item.channel?.registeredBy && x.paymentStatus == '0').length : 0;

                        let participant = item.channel?.channel?.contacts?.find(con => con.contactId == userData.contactId)?.eventParticipants.find(p => p.eventId == item.eventId);
                        let hideButton = false;

                        if (!hideButton && (userData.channelTypeId != 2 && item.buyerSecondaryToRegister == '0' && userData.roleId != 1) ||
                          (userData.channelTypeId == 2 && ((item.vendorSecondaryToRegister == '0' && userData.roleId != 1) || (item.type == 1 && item.channel?.registeredBy == userData.contactId))))
                          hideButton = true;

                        let packageChk =   item.packages;

                        return (

                          <Row className="justify-content-center">
                            <Col className="card-wrapper ct-example" lg="12" md="12" sm="12" >
                              <Card className="Bgbg">
                                <CardBody>

                                  <Row>
                                    <Col className="col-xl-2 col-lg-2 col-md-12 col-sm-12 mobi-res text-center">
                                      <a
                                        className="avatar avatar-xl mb-2 details"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} alt="..." src={item.logo ? process.env.REACT_APP_CDN_URL + item?.logo : "https://static.wixstatic.com/media/76c928_c8c2edb7ca4347cfb5f6a94cc986bdda~mv2.png/v1/fill/w_188,h_118,al_c,q_85,usm_0.33_1.00_0.00/RETAIL_CONNECT_ONE_TO_ONE_EMEA.webp"} />
                                      </a>


                                    </Col>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mobi-res3 text_elips" style={{ "maxHeight": "auto", "white-space": "initial" }}>
                                      <h3 className="mb-0 text-uppercase ">
                                        {item.event_name}
                                      </h3>
                                      <p>
                                        {item.description.replace(/<[^>]+>/g, '')}
                                      </p>
                                    </div>
                                    <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12 col-xs-12 mobi-res5 pr-0">
                                      <h3>
                                        Date and Venue
                                      </h3>

                                      <p className="mb-0">
                                        <i class="fa fa-calendar mr-2" aria-hidden="true"></i>{Moment(item.date_start).format('D MMMM y')} - {Moment(item.date_end).format('D MMMM y')}</p>
                                      <p className="mb-0"> <i class="fa fa-map-marker mr-2" aria-hidden="true"></i>{item?.location?.country}</p>
                                    </div>
                                    <div className="col-xl-2 col-sm-12 col-xs-12 mobi-res5 text-center">
                                      <div className="d-inline">

                                        {(item.eventStatus == 2 && Moment(item.date_start).format('yyyy') >= 2022 && Moment(item.date_start).format('M') >= 1)  && packageChk.length > 0 ?
                                          <>
                                            {item.type == 1 && (paymentPending != 0 || (item.channel && (item.channel?.approvalStatus == null || item.channel?.approvalStatus == 0))) && item.eventStatus != 3 && (item.channel?.registeredBy != userData.contactId && item.type == 1) &&
                                              <>
                                                <Button id='pendingtip' className="btn text-center mt-0" variant="contained" color="grey" style={{ backgroundColor: "gray", color: "white" }}>Register</Button>
                                                <UncontrolledTooltip target={'pendingtip'} placement="bottom">You cannot apply yet for the event as one of your colleague is currently pending for approval or payment, please check later</UncontrolledTooltip>
                                              </>
                                            }
                                            {/* before event code  */}
                                            {!hideButton && paymentPending == '0' && (!item.channel || item.channel?.approvalStatus == 1) && ((userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && !item.statusDate && item.statusDate === '' && (!item.channel || !participant || (item.channel?.approvalStatus == '1' && !item.channel?.channel?.contacts?.length)
                                              || (item.type == 2 && !item.channel?.channel?.contacts?.length)) && <Button className="btn btn-primary ch-btn-blue text-center mt-0 meet" variant="contained" color="primary" type="button"
                                                //disable register button temp
                                                onClick={() => {

                                                  if ((item.vendorStatus == 4 && userData.channelTypeId == 2) || (item.buyerStatus == 4 && userData.channelTypeId != 2)) {
                                                    //   if (userData.channelTypeId != 2)
                                                    //     setManage(item)
                                                    //   else
                                                    //     window.open('https://www.channel-summit.com/shop', '_blank');
                                                    if (item.vendorStatus == 4 && userData.channelTypeId == 2)
                                                      window.open(item.sellerEventRegisterURL, '_blank');
                                                    else if (item.buyerStatus == 4 && userData.channelTypeId != 2)
                                                      window.open(item.buyerEventRegisterURL, '_blank');
                                                  } else //{
                                                    //   if (userData.channelTypeId != 2)
                                                    selectEvent(item);
                                                  //   else
                                                  //     item.eventId == 5 ? window.open('https://www.channelconnect1to1.com/select-your-package', '_blank') : window.open('https://www.retailconnect1to1.com/product-page/GRC-ifa-22', '_blank');
                                                  // }
                                                  //registration(item);

                                                }
                                                }>Register</Button>}



                                            {/* prev code (16/8): userData.channelTypeId != 2 && */}
                                          </> : <div><Button className='mt-4 mb-3 btn btn-primary' size="sm" type="button">Coming Soon</Button>

                                          </div>

                                        }


                                      </div>

                                    </div>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        )
                      })}
                    {(unRegisteredEvent.length <= 0 && !loading) && <div className="no-records ">

                      <div className="no-img text-center" style={{ width: '32%' }}>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} /></div>

                      {
                        'Unfortunately, there is no matching events at the moment. '
                      }
                    </div>
                    }
                  </div>
                }

                {selectedTab == 3 &&
                  <div className="tabs-content">

                    {pastEvent.length > 0 &&
                      pastEvent.map(item => {

                        let participant = item.channel?.channel?.contacts?.find(con => con.contactId == userData.contactId)?.eventParticipants.find(p => p.eventId == item.eventId);
                        let hideButton = false;

                        if (!hideButton && (userData.channelTypeId != 2 && item.buyerSecondaryToRegister == '0' && userData.roleId != 1) ||
                          (userData.channelTypeId == 2 && ((item.vendorSecondaryToRegister == '0' && userData.roleId != 1) || (item.type == 1 && item.channel?.registeredBy == userData.contactId))))
                          hideButton = true;

                        return (

                          <Row className="justify-content-center">
                            <Col className="card-wrapper ct-example" lg="12" md="12" sm="12" >
                              <Card className="Bgbg">
                                <CardBody>
                                  {/* <Row className="align-items-center"> */}
                                  <Row>
                                    <Col className="col-xl-2 col-lg-2 col-md-12 col-sm-12 mobi-res text-center">
                                      <a
                                        className="avatar avatar-xl mb-2 details"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} alt="..." src={item.logo ? process.env.REACT_APP_CDN_URL + item?.logo : "https://static.wixstatic.com/media/76c928_c8c2edb7ca4347cfb5f6a94cc986bdda~mv2.png/v1/fill/w_188,h_118,al_c,q_85,usm_0.33_1.00_0.00/RETAIL_CONNECT_ONE_TO_ONE_EMEA.webp"} />
                                      </a>

                                      {/* Waiting for opening in event catalyst */}

                                      {/* <div class="event-meet col-xs-12">
                                  {item.vendorStatus == 4 && !!item.channel && !!item.channel?.channel?.contacts?.length && item.channel?.approvalStatus == 1 && item.channel?.channel?.contacts[0]?.eventParticipants[0]?.status == '1' && item.channel?.channel?.contacts[0]?.eventParticipants[0]?.isEventCatalyst == '1' &&
                                    <button className='mt-4 mb-3 btn btn-primary' size="sm" onClick={() => getManage(item)}>Meeting Scheduler</button>
                                  }
                                </div> */}

                                      {/* <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"  onClick={() => getManage(item)}>Meeting Scheduler</button> */}
                                    </Col>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mobi-res3 text_elips" style={{ "maxHeight": "auto", "white-space": "initial" }}>
                                      <h3 className="mb-0 text-uppercase ">
                                        {item.event_name}
                                      </h3>
                                      <p>
                                        {item.description.replace(/<[^>]+>/g, '')}
                                      </p>
                                    </div>
                                    <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12 col-xs-12 mobi-res5 pr-0">
                                      <h3>
                                        Date and Venue
                                      </h3>

                                      <p className="mb-0">
                                        <i class="fa fa-calendar mr-2" aria-hidden="true"></i>{Moment(item.date_start).format('D MMMM y')} - {Moment(item.date_end).format('D MMMM y')}</p>
                                      <p className="mb-0"> <i class="fa fa-map-marker mr-2" aria-hidden="true"></i>{item.location.country}</p>
                                    </div>
                                    <div className="col-xl-2 col-sm-12 col-xs-12 mobi-res5 text-center">
                                      <div className="d-inline">



                                        <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary" onClick={() => viewManage(item)}>View</button>



                                      </div>

                                    </div>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        )
                      })}
                    {(pastEvent.length <= 0 && !loading) && <div className="no-records ">
                      {/* <span>No Records</span> */}
                      <div className="no-img text-center" style={{ width: '32%' }}>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} /></div>

                      {
                        'Unfortunately, there is no matching events at the moment. '
                      }
                    </div>
                    }
                  </div>
                }
              </CardBody> </Card>
          }
        </Container>
      </div>
      <div style={{
        display: 'block', padding: 30
      }}>
        {companyLogoFlag &&
          <ImageLibrary getDocumentId={getCompanyLogo} aws_folderName='CompanyLogo' id={3} size={1}
            img={linkedData.companyLogoUrl ? linkedData.companyLogoUrl : process.env.REACT_APP_CDN_URL +
              "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />}

        {contactLogoFlag &&
          <ImageLibrary getDocumentId={getContactLogo} aws_folderName='CompanyLogo' id={3} size={1}
            img={linkedData.companyLogoUrl ? linkedData.companyLogoUrl : process.env.REACT_APP_CDN_URL +
              "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />}



        <Modal className="tempevent-modal" style={{ width: '1200px' }} isOpen={tempmodal} toggle={() => settempModal(!tempmodal)}>


          <ModalBody>
            <h2>Inform your Linkedin network about your participation to Channel Summit {linkedData.sharetitle} in just 1 click.</h2>
            <div className="row">
              <div className="col-lg-6">
                <div className="tempevent-left">
                  <h4>Share Your Company Participation</h4>
                  <div className="tempeft-image" id="linkedimage">
                    <img src={linkedData.linkedinImagePath} />
                    <div className="temp-cont" id="linkcont">
                      <div className="temp-logo" id="linklogo">
                        <span className="temp-edit" id="compedit"><EditIcon onClick={() => editCompanyLogo()} /></span>

                        <img src={linkedData.companyLogo} />
                      </div>
                    </div>
                    <p className="temp-para" id="linkpara"><span>{linkedData.firstName}</span> <span>{linkedData.lastName}</span><br /><span>{linkedData.companyName}</span><br /><span></span></p>

                  </div>
                  <Button className="share-linkedin" onClick={() => linkedin_share()}><ShareIcon /> Share in Linkedin </Button>
                  <Button className="share-linkedin down-btn" onClick={() => downloadCompanyShare()}>Download</Button>

                </div>
              </div>
              <div className="col-lg-6">
                <div className="tempevent-right">
                  <h4>Share Your Participation</h4>
                  <div className="tempeft-image" id="linkedimage1">
                    <img src={linkedData.linkedinImagePath} />
                    <div className="temp-cont" id="linkcont1">
                      <div className="temp-logo" id="linklogo1">
                        <span className="temp-edit" id="conedit"><EditIcon onClick={() => editContactLogo()} /></span>

                        <img src={linkedData.contactLogo} />
                      </div>
                    </div>
                    <p className="temp-para" id="linkpara1"><span>{linkedData.firstName}</span> <span>{linkedData.lastName}</span><br /><span>{linkedData.companyName}</span><br /><span></span></p>

                  </div>
                  <Button className="share-linkedin" onClick={() => linkedin_share1()}><ShareIcon /> Share in Linkedin </Button>
                  <Button className="share-linkedin down-btn" onClick={() => downloadContactShare()}>Download</Button>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => settempModal(!tempmodal)}>Close</Button>
          </ModalFooter>
        </Modal>
      </div >



    </>
  )
}






export default EventsNew;